.pop-up {
  position: fixed;
  width: 100%;
  z-index: 2;
  justify-self: center;
  height: 100%;
}

.pop-up__container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
}

.pop-up-blure {
  filter: blur(0.2vw);
}

@media (max-width: 770px) {
  .pop-up {
    display: flex;
    justify-content: center;
    left: 0;
  }
  .pop-up-blure {
    filter: blur(0.7vw);
  }
  .pop-up__container {
    min-height: 75vh;
    width: calc(100% - 6vw);
  }
}