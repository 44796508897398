.container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    border-radius: 0.5vw;
    box-shadow: 0px 0px 0.12vw 0px #000040;
    margin: 0;
    border: none;
    padding: 1vw 20px;
}
.coordinates {
    display: flex;
    flex-direction: column;
    gap: 0.3vw;
}
.coordinates input{
    width: 4vw;
    height: 1.5vw;
    border-radius: 0.2vw;
    box-shadow: 0px 0px 0.12vw 0px #000040;
    border: 0px transparent solid;
    text-align: center;
    border: 0.15vw solid white;
    font-size: 0.9vw;
}

.coordinates input[type = 'number']{
    outline: none;
}

.textLocation__legendStyle{
    margin-left: 0.7vw;
    padding: 0 0.3vw;
    color: #808080;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.61vw;
    z-index: 2;
    background-color: white;
}
/* fieldset div {
    display: grid;
    justify-content: center;
} */