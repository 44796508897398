.selectCase{
    display: flex;
    flex-direction: row;
    border: 0.2vw solid var(--main-color);
    -webkit-box-shadow: 0px 0px 0.5vw 0px #00000040;
    box-shadow: 0px 0px 0.5vw 0px #00000040; 
    width: 100%;
    height: 5vw;
    border-radius: 1.5vw;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}

.selectCase__nameCase{
    width: 45%;
    height: 100%;
    /* border: 2px red solid; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.selectCase__nameCase span, .selectCase__statusCase span, .selectCase__dateCase span{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    padding: 0 1vw;
    text-overflow: ellipsis;
    font-size: 1vw;
    font-family: 'Roboto';
}

.selectCase__dateCase{
    width: 17%;
    height: 100%;
    /* border: 2px green solid; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.selectCase__statusCase{
    width: 18%;
    height: 100%;
    /* border: 2px green solid; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.selectCase__buttonEdit{
    width: 15%;
    height: 100%;
    padding: 0 1vw;
    /* border: 2px green solid; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.selectCase__line {
    height: 2vw;
    width: 0.05vw;
    margin: 0 0.1vw;
    background-color: #eeeeee;
}


@media (max-width: 770px) {
    .selectCase__line {
        height: 0.1vw;
        width: 35%;
        background-color: #eeeeee;
        margin: 2vw 0;
    }

    .selectCase{
        display: flex;
        flex-direction: column;
        border: 0.9vw solid var(--main-color);
        -webkit-box-shadow: 0px 0px 3vw -1vw #00000040;
        box-shadow: 0px 0px 3vw -1vw #00000040;
        width: 100%;
        height: auto;
        border-radius: 2vw;
        align-items: none;
        justify-content: none;
        gap: 1vw 0;
        padding: 4vw 0;
    }



    .selectCase__nameCase{
        width: 100%;
        height: auto;
        padding: 0vw;
        /* border: 2px red solid; */
        display: flex;
        justify-content: center;
    }
    
    .selectCase__nameCase span, .selectCase__statusCase span, .selectCase__dateCase span{
    font-size: 4vw;
    padding: 0 3vw;
    }
    
    .selectCase__dateCase{
        width: 100%;
        height: auto;
        /* border: 2px green solid; */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .selectCase__statusCase{
        width: 100%;
        height: auto;
        /* border: 2px green solid; */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .selectCase__buttonEdit{
        width: 100%;
        height: auto;
        padding: 0vw;
        padding-top: 4vw;
        /* border: 2px green solid; */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    }
