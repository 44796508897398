.loadingAnimation {
    width: 10vw;
    height: 10vw;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    text-align: center;
    background-color: white;
    z-index: 9;
}

.loadingAnimation span {
    font-family: "Roboto";
    font-size: 1.4vw;
    font-weight: 500;
    z-index: 10;
}

.loadingAnimation:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0.155vw solid #000000;
    border-right-color: var(--main-color-border);
    border-radius: 50%;
    -webkit-animation: loadingAnimation-circle 1.1s linear infinite;
    animation: loadingAnimation-circle 1.1s linear infinite;
    background-color: white;
    z-index: 9;
}

.container {
    left: 0;
    position: fixed;
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: white;
    z-index: 9;
    top: 0;
    left: 0;
    transition: width  0.5s, left 0.5s;
}

@-webkit-keyframes loadingAnimation-circle {
    0% {
        border-radius: 35%;
        border-style: dashed dotted;
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    50% {
        border-radius: 45%;
        border-style: dotted dashed;
    }

    100% {
        border-radius: 35%;
        border-style: dashed dotted;
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loadingAnimation-circle {
    0% {
        border-radius: 35%;
        border-style: dashed dotted;
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    50% {
        border-radius: 45%;
        border-style: dotted dashed;
    }

    100% {
        border-radius: 35%;
        border-style: dashed dotted;
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media (max-width: 770px) {

    .container {
        width: 100vw;
        height: 100%;
        position: fixed;
    }

    .loadingAnimation {
        width: 30vw;
        height: 30vw;
    }

    .loadingAnimation span {
        font-family: "Roboto";
        font-size: 4vw;
        font-weight: 500;
        z-index: 10;
    }

    .loadingAnimation:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0.7vw solid #000000;
        border-right-color: var(--main-color-border);
        border-radius: 70%;
        -webkit-animation: loadingAnimation-circle 1.1s linear infinite;
        animation: loadingAnimation-circle 1.1s linear infinite;
        background-color: white;
        z-index: 9;
    }
}