.style__input__block {
    box-shadow: 0px 0px 0.12vw 0px #000040;
    border-radius: 0.5vw;
    width: 100%;
    justify-content: right;
    align-items: center;
    padding: 0 0px;
    display: inline-flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0.1vw solid white;
}

.style__input {
    width: 100%;
    height: 11vw;
    border-radius: 1vw;
    box-sizing: border-box;
    outline: none;
    font-size: 1vw;
    margin: 0;
    padding: 0;
    overflow-y: hidden;
    font-family: "Roboto";
    padding: 0.5vw;
    line-height: 1.5vw;
}

textarea {
    border: none;
    outline: none;
    resize: none;
}

.style__input____block__image {
    width: 98%;
    height: 1px;
    display: flex;
    justify-content: end;

}

.style__input____block__image h3 {
    position: relative;
    bottom: 2vw;
    right: 0px;
    color: gray;
    font-size: 0.6vw !important;
    font-family: Arial, Helvetica, sans-serif;
}

.style__input____block__image img {
    max-width: 70%;
}

.style__input__block:focus-within {
    /* box-shadow: 0px 0px 0.3vw 0px var(--hover-color); */
    border: 0.1vw solid var(--hover-color);
}


.inputBlock__lable{
    color: #808080;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1vw;
    /* line-height: 1.4375em;
    letter-spacing: 0.00938em; */
    padding: 0px;
    display: block;
    transform-origin: left top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
    position: absolute;
    left: 1.05vw;
    top: 0.6vw;
    transform: translate(0, 0) scale(1);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    z-index: 2;
    pointer-events: none;
    /* border: 2px red solid; */
}

.inputBlock__lable__focus{
    color: #808080;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.8vw;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0px;
    display: block;
    transform-origin: left top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 32px);
    position: absolute;
    left: 0px;
    top: 0px;
    transform: translate(0.77vw, -0.5vw) scale(0.75);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    z-index: 2;
    pointer-events: auto;
    user-select: none;
    background-color: white;
    padding: 0 0.4vw;
}

@media (max-width: 770px) {
    .style__input__block {
        box-shadow: 0px 0px 2vw -0.7vw #00000040;
        border: solid 0.2vw #bbbbbb;
        border-radius: 2vw;
        width: 100%;
        justify-content: right;
        align-items: center;
        padding: 0 0.1vw;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-tap-highlight-color: transparent;
        margin-bottom: 5vw;
    }


    .style__input {
        width: 95%;
        height: 35vw !important;
        max-width: 95%;
        border-radius: 2vw;
        outline: none;
        font-size: 3.5vw;
        margin: 0;
        padding: 0;
        overflow-y: hidden;
        font-family: "Roboto";
        padding: 2.5vw;
        line-height: 3.7vw;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-tap-highlight-color: transparent;
        /* border: 2px red solid; */
    }

    .style__input__block:focus-within {
        /* box-shadow: 0px 0px 2vw -0.7vw var(--hover-color); */
        border: 0.1vw solid var(--hover-color);
    }

    .style__input____block__image {
        width: 94%;
        height: 0.1vw;
        display: flex;
        justify-content: end;
        /* border: 2px red solid; */
    }

    .style__input____block__image h3 {
        position: relative;
        bottom: 7vw;
        right: 0px;
        color: gray;
        font-size: 2.5vw !important;
        font-family: Arial, Helvetica, sans-serif;
    }

    .inputBlock__lable{
       
        font-size: 3vw;
        left: 3vw;
        top: 3.5vw;
        transform: translate(0, 0) scale(1);
    }
    
    .inputBlock__lable__focus{
        font-size: 4vw;
        transform: translate(3vw, -2vw) scale(0.75);
        padding: 0 3vw;
    }
}