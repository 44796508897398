.mentor-case__first-block-style {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

.mentor-case__mentor-case-profie__title {
  width: 100%;
  margin-bottom: 2vw;
}

.mentor-case__mentor-case-profie-info-about-case {
  display: flex;
  flex-direction: column;
}

.mentor-case-gap {
  gap: 30px;
}

.mentor-case__mentor-case-profie__info-about-case {
  margin-top: 2vw;
  width: 100%;
}

@media (max-width: 770px) {
  .mentor-case__first-block-style {
    margin-top: 19px;
    display: grid;
    gap: 10px;
  }
}