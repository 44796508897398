@font-face {
    font-family: "Czizh Body";
    src: url('assets/fonts/Czizh Body.ttf') format('truetype');
}
@font-face {
    font-family: "Czizh Feather";
    src: url('assets/fonts/Czizh Feather.ttf') format('truetype');
}
@font-face {
    font-family: "Czizh Wing";
    src: url('assets/fonts/Czizh Wing.ttf') format('truetype');
}
@font-face {
    font-family: "lilita";
    src: url('assets/fonts/lilita.ttf') format('truetype');
}
@font-face {
    font-family: "rob";
    src: url('assets/fonts/rob.ttf') format('truetype');
}
@font-face {
    font-family: "zay";
    src: url('assets/fonts/zay.ttf') format('truetype');
}
@font-face {
    font-family: "Roboto-Black";
    src: url('assets/fonts/Roboto-Black.ttf') format('truetype');
}
@font-face {
    font-family: "Roboto-BlackItalic";
    src: url('assets/fonts/Roboto-BlackItalic.ttf') format('truetype');
}
@font-face {
    font-family: "Roboto-Bold";
    src: url('assets/fonts/Roboto-Bold.ttf') format('truetype');
}
@font-face {
    font-family: "Roboto-BoldItalic";
    src: url('assets/fonts/Roboto-BoldItalic.ttf') format('truetype');
}
@font-face {
    font-family: "Roboto-Italic";
    src: url('assets/fonts/Roboto-Italic.ttf') format('truetype');
}
@font-face {
    font-family: "Roboto-Light";
    src: url('assets/fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
    font-family: "Roboto-LightItalic";
    src: url('assets/fonts/Roboto-LightItalic.ttf') format('truetype');
}
@font-face {
    font-family: "Roboto-Medium";
    src: url('assets/fonts/Roboto-Medium.ttf') format('truetype');
}
@font-face {
    font-family: "Roboto-MediumItalic";
    src: url('assets/fonts/Roboto-MediumItalic.ttf') format('truetype');
}
@font-face {
    font-family: "Roboto-Regular";
    src: url('assets/fonts/Roboto-Regular.ttf') format('truetype');
}
@font-face {
    font-family: "Roboto-Thin";
    src: url('assets/fonts/Roboto-Thin.ttf') format('truetype');
}
@font-face {
    font-family: "Roboto-ThinItalic";
    src: url('assets/fonts/Roboto-ThinItalic.ttf') format('truetype');
}
