.map__style h3 {
    font-size: 18pt;
    color: #242424;
    font-family: "Roboto";
}

.map__style {
    margin-top: 1.5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.map__style__block {
    border-radius: 2vw;
    box-shadow: 0px 0px 0.5vw 0px #00000040;
    border-width: 0;
    width: 60vw;
    height: 23vw;
    border: solid 0.2vw var(--main-color-border);
}

.map__title{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.map__title_wrapper{
    width: 60vw;
}

@media(max-width: 3841px) {
    .map__style h3 {
        font-size: 1.2vw;
    }
}

@media(max-width: 2561px) {
    .map__style h3 {
        font-size: 1.1vw;
    }
}


@media(max-width: 1921px) {

    .map__style h3 {
        font-size: 1vw;
    }
}


@media(max-width: 1280px) {
    .first__block span {
        font-size: 1vw;
    }

    .title__block span {
        font-size: 1vw;
    }

    .new__style__props {
        font-size: 0.9vw !important;
    }

    .text__span__style {
        font-size: 0.9vw;
    }
}


@media (max-width: 770px) {

    .map__style h3 {
        font-size: 4vw;
        color: #242424;
        font-family: "Roboto";
        font-weight: 500;
    }

    .map__style__block {
        border-radius: 4vw;
        box-shadow: 0px 0px 3vw -1vw #00000040;
        width: 85%;
        height: 53vw;
        margin-bottom: 2vw;
        border: 0.9vw solid var(--main-color);
    }


    .map__title_wrapper{
        width: 85%;
    }
}


@media (max-width: 992px) {}


@media (max-width: 767px) {}


@media (max-width: 650) {}


@media (max-width: 480px) {}