.main__block__style {
    box-shadow: 0px 0px 0.5vw -0.2vw #00000040;
    border-radius: 1vw;
    width: 100%;
    height: 5vw;
    background-color: white;
    margin-bottom: 2vw;
    border: solid 0.2vw var(--main-color-border);
    display: flex;
    flex-direction: row;
}

.icon {
    fill: var(--main-color);
    font-size: var(--font-size-middle);
    margin-right: 10px;
}

.icon_download {
    font-size: calc(var(--font-size-middle) + 8pt);
}

.profileOccasion__inblock{
    width: 100%;
    height: 100%;
}

.info__block__style {
    display: flex;
    height: 2.2vw;
    align-items: center;
    width: 100%;
}

.info__block__first {
    width: 20%;
    /* border: 2px red solid; */
    margin-left: 1vw;
    display: flex;
    justify-content: start;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.info__block__second {
    width: 40%;
    /* border: 2px violet solid; */
    margin-left: 1vw;
    display: flex;
    justify-content: start;
    align-items: center;
}

.info__block__style span {
    overflow: hidden;
    text-overflow: ellipsis;
}

.info__block__third {
    width: 20%;
    /* border: 2px blue solid; */
    margin-left: 1vw;
    display: flex;
    justify-content: start;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.info__block__fourth {
    width: 20%;
    /* border: 2px orange solid; */
    margin-left: 1vw;
    display: flex;
    justify-content: start;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.title__block__style {
    /* border: 2px solid green; */
    display: flex;
    justify-content: center;
    height: 2.5vw;
    align-items: center;
}

.title__block__style h3 {
    margin-left: 0.6vw;
    font-size: 1vw;
    font-family: "Roboto";
    font-weight: 500;
}

.info__block__style img {
    width: 1.2vw;
    height: 1.2vw;
    margin-right: 0.3vw;
}


.info__block__style span {
    /* border: 2px red solid; */
    width: 100%;
    font-family: "Roboto";
    font-size: 0.9vw;
    font-weight: 500;
    /* padding-right: 15.5vw; */
}

.info__block__line {
    width: 0.1vw;
    height: 60%;
    background-color: #D5D5D5;
}

.style__profile__data {
    font-weight: 500;
    font-size: 0.8vw;
}

.info__block__download img{
 width: 2vw;
 height: 2vw;
}

.info__block__download{
    margin-right: 1vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

@media(max-width: 770px) {
    .main__block__style {
        display: flex;
        flex-direction: column;
        box-shadow: 0px 0px 3vw -1vw #00000040;
        border: 0.9vw solid var(--main-color);
        border-radius: 4vw;
        width: 100%;
        height: auto;
        background-color: white;
        padding-bottom: 1vw;
        margin-bottom: 4vw;
    }

    .info__block__first {
        width: 100%;
        /* border: 2px red solid; */
        margin-left: 0;
        display: flex;
        justify-content: start;
        align-items: center;
        white-space: pre-wrap;
        text-overflow: ellipsis;
    }

    .title__block__style h3 {
        padding: 0;
        margin: 0;
        margin-left: 2vw;
        font-size: 4vw;
        font-family: "Roboto";
        font-weight: 500;
        /* border: 2px solid violet; */
    }
    .title__block__style {
        /* border: 2px solid green; */
        display: flex;
        justify-content: center;
        height: auto;
        align-items: center;
        padding: 0;
        margin-top: 1vw;
    }
    
    .info__block__line {
        width: 0vw;
        height: 0%;
        background-color: #D5D5D5;
        /* border: 2px red solid; */
    }
    .info__block__style img {
        width: 4vw;
        height: 4vw;
        margin-right: 1vw;
        margin-left: 2vw;
    }


    .info__block__style {
        display: flex;
        flex-direction: column;
        height: auto;
        align-items: start;
        width: 100%;
        padding: 0 10px 10px 10px;
        box-sizing: border-box;
        /* margin-left: 0; */
    }
    .info__block__second {
        width: 100%;
        /* border: 2px violet solid; */
        margin-left: 0;
        display: flex;
        justify-content: start;
        align-items: center;
        white-space: pre-wrap;
        text-overflow: ellipsis;
    }

    .style__profile__data {
        font-weight: 500;
        font-size: 3.5vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .info__block__style span {
        /* border: 2px red solid; */
        margin: 1vw 0;
        /* width: 100%; */
        font-family: "Roboto";
        font-size: 3.8vw;
        font-weight: 500;
        max-width: 100%;
        display: flex;
        /* padding-right: 15.5vw; */
    }

    .info__block__third {
        width: 100%;
        /* border: 2px blue solid; */
        margin-left: 0;
        display: flex;
        justify-content: start;
    }
    .info__block__fourth {
        width: 100%;
        /* border: 2px orange solid; */
        margin-left: 0;
        display: flex;
        justify-content: start;
        align-items: center;
        white-space: pre-wrap;
        text-overflow: ellipsis;
    }  

    .icon {
        fill: var(--main-color);
        font-size: var(--font-size-middle);
        margin-right: 10px;
    }
    
    .icon_download {
        font-size: calc(var(--font-size-middle) + 16pt);
    }
       .info__block__download{
           justify-content: space-evenly;
       }
}