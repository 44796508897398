.events__create__style li {
    display: flex;
    list-style: none;
    margin-bottom: 1vw;
}

.li__style {
    margin-bottom: 2vw !important;
}

.task_container {
    display: grid;
    gap: 25px;
}

.events__create__style span {
    font-size: 0.9vw;
    /* border: 2px violet solid; */
    font-family: "Roboto";
}

.events__create__style {
    /* border: 2px red solid; */
    display:  flex;
    flex-direction: column;
}

.text__style {
    width: 7vw;
    height: 2vw;
    display: flex;
    align-items: center;
}

.h3__style {
    font-size: 1.2vw;
    font-family: "Roboto";
    font-weight: 500;
    display: flex;
    justify-content: center;
    margin-bottom: 7vw;

}

@media (max-width: 770px) {
    .h3__style {
        font-size: 4vw;
        font-family: "Roboto";
        font-weight: 500;
        display: flex;
        justify-content: center;
        margin-bottom: 7vw;

    }

    .input__cusom__style {
        margin: 0 !important;
    }
}