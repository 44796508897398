.contactcard{
    display: flex;
    border: solid 0.2vw var(--main-color-border);
    width: 25vw;
    height: auto;
    border-radius: 1vw;
    box-shadow: 0px 0px 0.5vw 0px #00000040;
    overflow: hidden;
    padding: 0.4vw;
    align-items: center;
    transition-duration: 0.5s;
    background-color: white;
}


.contactcard__img{
    width: 40%;
}

.contactcard__icon{
    width: auto;
    height: 1.3vw;
    margin-right: 1vw;
}

.contactcard__fullname{
    font-size: 1.1vw;
    width: 100%;
}

.contactcard__main__title{
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-left: 1vw;
    align-items: left;
}

.contactcard__post{
    color: #808080;
    font-size: 0.8vw;
    width: 100%;
}

.contactcard__about{
    background-color: #f4f4f6;
    color: #808080;
    width: 90%;
    font-size: 0.9vw;
    height: auto;
    padding: 0.5vw;
    margin: 1vw 0;
    border-radius: 0.5vw;
}

.contactcard__socity{
    width: 100%;
}

.contactcard__socity a{
    cursor: pointer;
}


.contactcard:hover .contactcard__fullname{
    color: var(--main-color);
}

.contactcard:hover{
    
    transition-duration: 0.5s;
    transform: scale(1.05);
}


@media (max-width: 770px) {
    .contactcard{
        width: 100%;
        height: auto;
        border-radius: 2vw;
        box-shadow: 0px 0px 3vw -1vw #00000040;

        padding: 0.4vw;
       border: 0.9vw solid var(--main-color);
        margin-bottom: 5vw;
    }

    .contactcard__fullname{
        font-size: 4vw;
    }

    .contactcard__about{
        font-size: 3.5vw;
        padding: 1.5vw;
        margin: 2vw 0;
        border-radius: 1vw;
    }

    .contactcard__post{ 
        font-size: 3vw;  
    }

    .contactcard__icon{
        width: auto;
        height: 5vw;
        margin-right: 4vw;
    }
    

    }
