.support-container {
  overflow-y: auto;
}

.author-style {
  width: 60vw;
  display: flex;
  justify-content: space-between;
}

.wrapper-support {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2vw;
}

.title-main {
  width: 60vw;
}

@media (max-width: 770px) {
  .author-style {
    width: 85%;
    display: flex;
    flex-direction: column;
  }
  .wrapper-support {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
  .title-main {
    width: 85%;
  }
}