@charset "UTF-8";

@font-face {
    font-family: "Notification";
    font-weight: normal;
    font-style: normal;
}

.notification-container {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999999;
    width: 16.96vw;
    padding: 0px 0.795vw;
    max-height: calc(100% - 1.59vw);
    overflow-x: hidden;
    overflow-y: auto;
}

.notification {
    box-sizing: border-box;
    padding: 0.795vw 0.795vw 0.795vw 3.074vw;
    border-radius: 0.106vw;
    color: #fff;
    background-color: #ccc;
    cursor: pointer;
    font-size: 1em;
    line-height: 1.2em;
    position: relative;
    opacity: 0.9;
    margin-top: 0.795vw;
    border-radius: 1vw;
}

.notification .title {
    font-size: 1em;
    line-height: 1.2em;
    font-weight: bold;
    margin: 0 0 0.265vw 0;
}

.notification:hover,
.notification:focus {
    opacity: 1;
}

.notification-enter {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
}

.notification-exit {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.notification-exit.notification-exit-active {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
}

.notification:before {
    position: absolute;
    top: 50%;
    left: 0.795vw;
    margin-top: -0.742vw;
    display: block;
    font-family: "Notification";
    width: 1.484vw;
    height: 1.484vw;
    font-size: 1.484vw;
    text-align: center;
    line-height: 1.484vw;
}

.notification-info {
    background-color: #2f96b4;
}

.notification-info:before {
    content: "I";
    font-family: "Roboto";
}

.notification-success {
    background-color: #51a351;
}

.notification-success:before {
    content: "S";
    font-family: "Roboto";
}

.notification-warning {
    background-color: #f89406;
}

.notification-warning:before {
    content: "W";
    font-family: "Roboto";
}

.notification-error {
    background-color: #bd362f;
}

.notification-error:before {
    content: "E";
    font-family: "Roboto";
}


.notific__style {
    font-family: "Roboto";
    font-size: 0.8vw;
}

@media (max-width: 770px) {
    .notification-container {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 999999;
        width: 70%;
        padding: 0px 0.795vw;
        max-height: calc(100% - 1.59vw);
        overflow-x: hidden;
        overflow-y: auto;
        /* border: 2px red solid; */
    }

    .notification {
        box-sizing: border-box;
        padding: 2.5vw 3.795vw 1.795vw 10.074vw;
        border-radius: 0.106vw;
        color: #fff;
        background-color: #ccc;
        cursor: pointer;
        font-size: 3vw;
        line-height: 1.2em;
        position: relative;
        opacity: 0.95;
        margin-top: 0.795vw;
        border-radius: 3vw;
    }

    .notification-info {
        background-color: #2f96b4;
    }

    .notification-info:before {
        content: "I";
        font-family: "Roboto";
    }

    .notification-success {
        background-color: #51a351;
    }

    .notification-success:before {
        content: "S";
        font-family: "Roboto";
    }

    .notification-warning {
        background-color: #f89406;
    }

    .notification-warning:before {
        content: "W";
        font-family: "Roboto";
    }

    .notification-error {
        background-color: #bd362f;
    }

    .notification-error:before {
        content: "E";
        font-family: "Roboto";
    }


    .notific__style {
        font-family: "Roboto";
        font-size: 3vw;
    }


    .notification .title {
        font-size: 3vw;
        line-height: 3vw;
        font-weight: bold;
        margin: 0 0 0.265vw 0;
    }

    .notification:before {
        position: absolute;
        top: 50%;
        left: 3.795vw;
        margin-top: -0.742vw;
        display: block;
        font-family: "Roboto";
        width: 1.484vw;
        height: 1.484vw;
        font-size: 5vw;
        text-align: center;
        line-height: 1.484vw;
    }

}