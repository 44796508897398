.main__block__style{
    display: flex;
    flex-direction: column;
    margin-top: 3vw;
    box-shadow: 0px 0px 0.53vw 0px #00000040;
    padding: 1.5vw;
    border-radius: 1vw;
    border: solid 0.2vw var(--main-color-border);
}

.first__line__style{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0.25vw 0;
    border-radius: 5vw;
    padding: 0.5vw 0;
}

.span__style{
    width: 100%;
    /* border: 2px red solid; */
    text-align: center;
    font-size: 0.9vw;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; 
    word-wrap: break-word; 
}

@media (max-width: 770px) {

    .first__line__style{
        margin: 0.5vw 0;
        border-radius: 2.5vw;
        padding: 0.7vw;
    }

    .main__block__style{
        margin-top: 7vw;
        padding: 2vw 3vw;
        box-shadow: 0px 0px 3vw -1vw #00000040;
        border: 0.9vw solid var(--main-color);
        border-radius: 4vw;
    }

    .span__style{
        text-align: center;
        font-size: 3.5vw;
        font-weight: 500;
    }
}
