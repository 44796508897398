.admin-upload-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media (max-width: 770px) {
  .admin-upload-page-container {
    align-items: normal;
  }
}