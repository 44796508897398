.news__style {
    width: 50%;
    height: auto;
    padding: 2vw 2vw;
    background-color: white;
    box-shadow: 0px 0px 0.5vw 0px #00000040;
    border: solid 0.2vw var(--main-color-border);
    border-radius: 2vw;
    display: flex;
    flex-direction: column;
    margin: 0.3vw;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    gap: 1vw;
    /* No
    /* overflow: hidden; */

    /* border: 2px red solid; */
    /* border: 2px red solid; */
}

.first__style_box {
    width: 100%;
}

.second__style_box {
    /* border: blue 2px solid; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title__style {
    height: 20%;
    /* border: violet 2px solid; */
    color: #242424;
    padding: 0vw;
}

.main__text__style {
    height: 80%;
    /* border: red 2px solid; */
    color: #242424;
    white-space: pre-wrap;
}

.wrapper__style {
    height: 100%;
    display: flex;
    align-items: center;
    /* border: 2px orange solid; */
}

.wrapp {
    background-color: #DDDDDD;
    width: 0.1vw;
    height: 80%;
}

.img__style img {
    border-radius: 1vw;
    max-height: 26.5vw;
    max-width: 26.5vw;

    /* border: black 2px solid; */
}

.img__style {
    /* border: 2px red solid; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.title__style span {
    font-weight: 500;
    display: flex;
    height: 100%;
    font-size: 1vw;
    font-family: "Roboto";
    align-items: start;
    justify-content: start;
    margin-bottom: 1vw;

}

.main__text__style span {
    padding: 0;
    display: flex;
    height: 100%;
    font-size: 0.9vw;
    font-family: "Roboto";
    font-weight: 500;
    /* border: violet 2px solid; */
    align-items: start;
    text-align: left;
    line-height: 1.1vw;
}

.news__header img {
    width: 3vw;
    border-radius: 10vw;
    border: 0.2vw solid var(--main-color-border);

}

.news__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 0.5vw;
}

.news__header a {
    text-decoration: none;
    font-family: "Roboto";
    font-weight: 600;
    font-size: 0.9vw;
    color: var(--main-color);
    padding: 0;
    margin: 0;
}

.news__header__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5vw;

}

.news__header__title p {
    padding: 0;
    margin: 0;
    color: #c1c1c1;
    font-size: 0.75vw;

}

.news__upper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


@media(max-width: 3841px) {
    .main__text__style span {
        font-size: 1vw;
        line-height: 1.35vw;
    }

    .title__style span {
        font-size: 1.2vw;
    }
}

@media(max-width: 2561px) {
    .main__text__style span {
        font-size: 0.9vw;
        line-height: 1.25vw;
    }

    .title__style span {
        font-size: 1.1vw;
    }
}

@media(max-width: 1921px) {
    .main__text__style span {
        font-size: 0.8vw;
        line-height: 1.25vw;
    }

    .title__style span {
        font-size: 1vw;
    }
}

@media(max-width: 1280px) {
    .main__text__style span {
        font-size: 1vw;
        line-height: 1.35vw;
    }

    .title__style span {
        font-size: 1.15vw;
    }
}

/* __________________________________________________________________________ */


@media (max-width: 770px) {

    .news__style {
        width: 100%;
        height: auto;
        box-shadow: 0px 0px 3vw -1vw #00000040;
        border: 0.9vw solid var(--main-color);
        border-radius: 4vw;
        margin: 0;
        padding: 0;
        margin-bottom: 4vw;
        flex-direction: column;
    }

    .wrapper__style {
        width: 100%;
        height: 0;
        justify-content: center;
        margin: 1vw 0;
    }

    .wrapp {
        background-color: #DDDDDD;
        width: 0%;
        height: 0.106vw;
        /* border: 2px black solid; */
    }

    .title__style span {
        text-align: start;
        font-weight: 500;
        display: flex;
        font-size: 4vw;
        margin-bottom: 2vw;
        /* border: 2xp red solid; */
    }

    .title__style {
        display: flex;
        /* border: black 2px solid; */
        margin: 2vw 0;
        height: auto;
        padding: 0;
        border-bottom: 0.053vw #DDDDDD solid;


    }

    .first__style_box {
        width: 100%;
        margin: 0;
        padding: 0 3vw;
        box-sizing: border-box;
        margin-bottom: 3vw;

    }

    .main__text__style {
        width: 100%;
        height: auto;
        color: #242424;
        padding: 0;
        margin: 0;
    }

    .main__text__style span {
        text-align: start;
        align-items: start;
        height: auto;
        font-size: 3.5vw;
        line-height: 4vw;
        padding: 0;
    }

    .img__style img {
        border-radius: 5vw;
        max-height: 85vw;
        max-width: 85vw;
    }

    .second__style_box {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .news__header img {
        width: 12vw;
        border-radius: 100vw;
        border: 1vw solid var(--main-color-border);
    }

    .news__header {
        gap: 5vw;
    }

    .news__header a {
        font-size: 4vw;
    }


    .news__header__title p {
        font-size: 3vw;
    }

    .news__upper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 3vw;
        /* border: 2px red solid; */
    }
}


@media (max-width: 992px) {}


@media (max-width: 767px) {}


@media (max-width: 650) {}


@media (max-width: 480px) {}