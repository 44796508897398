.profile__style__main ul {
    list-style: none;
    margin-left: 4vw;
    width: 90%;
}

.profile__style__main li {
    list-style: none;
    /* padding-left: 4vw; */
    width: 100%;

    display: grid;
    grid-template-areas: ". .";
    gap: 0 1.7vw;
    justify-content: start;
    align-items: center;
    margin-bottom: 2vw;
}

.profile__style__main {
    margin-top: 2vw;
    /* border: 2px red solid; */
    display: flex; 
    flex-direction: column;
    align-items: center;
}


.radio__style {
    font-size: 1.2vw;
    font-family: "Roboto";
    font-weight: 500;
    color: #242424;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2vw;
    margin-right: 1vw;

}

.input__block {
    display: flex;
    /* border: violet 2px solid; */
}

/*Стиль radio btn */

.form_radio_btn {
    margin-left: 2vw;

}
.form_radio_btn:first-of-type {
    margin-left: 0;
}


.form_radio_btn input[type=radio] {
    display: none;
}

.form_radio_btn label {
    width: 3vw;
    height: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 0.4vw;
    user-select: none;
    font-family: "Roboto";
    font-size: 0.9vw;
    box-shadow: 0px 0px 0.3vw 0px #00000040;
    background-color: white;
    padding: 0.4vw 1.5vw;
    font-weight: 500;
    border: 0.2vw transparent solid;
}

/* Checked */
.form_radio_btn input[type=radio]:checked+label {
    border: 0.2vw var(--hover-color) solid;

}

.style__link__access{
    color:var(--main-color);
}

/* Disabled */
.form_radio_btn input[type=radio]:disabled+label {}


/* .checkbox__style{
    font-size: 1vw !important;
    width: 2vw !important;
    height: 2vw !important;
    border: 2px red solid;
} */

.span__replace__color span{
color: red !important;
}

.span__replace__color__main{
    color: #808080 !important;
    }
.style__link__access:hover{
 text-decoration: underline;
}

.third__block__about {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4vw;
    width: 100%;
}

.docum__text__style {
    font-size: 0.9vw;
    font-family: "Roboto";
    text-align: center;
    margin-bottom: 1vw;
}

.doc__main__block {
    display: flex;
    justify-content: space-evenly;
    /* border: 2px red solid; */
    width: 100%;
}

.document__style a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
}

.document__style a span {
    font-size: 0.75vw;
    font-family: "Roboto";
    color: #000000;
    width: 100%;
    text-align: center;
}

.document__style a span:hover {
    color: #a0a0a0;
}

.icon__style {
    width: 3vw;
    height: 3vw;
}

.profile__image{ 
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
}

.style__text__image{
    font-size: 1vw;
    color: #a0a0a0;
    /* border: 2px red solid; */
    width: 50vw;
    text-align: center;
}

@media(max-width: 3841px) {
    .profile__style__main ul {
        margin-left: 2vw;
    }
}

@media(max-width: 2561px) {
    .profile__style__main ul {
        margin-left: 1vw;
    }
}

@media(max-width: 1921px) {
    .profile__style__main ul {
        margin-left: 0;
    }
}

.button__style__center {
    display: flex;
    justify-content: center;
}

@media(max-width: 770px) {
    .profile__style__main ul {
        list-style: none;
        margin: 0;
        width: 100%;
        padding: 0;
        /* border: 2px green solid; */

    }

    .profile__style__main {
        width: 100%;
        margin-top: 5vw;
        /* border: 2px red solid; */
        /* border: rgb(20, 241, 20) 2px solid; */

    }

    .profile__style__main li {
        width: 100%;
        /* border: orange 2px solid; */
        display: flex;
    }

    .form_radio_btn label {
        display: inline-block;
        cursor: pointer;
        border-radius: 3.5vw;
        user-select: none;
        font-family: "Roboto";
        font-size: 3.5vw;
        box-shadow: 0px 0px 3vw -1vw #00000040;
        background-color: white;
        width: 20vw;
        height: 10vw;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1vw transparent solid;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-tap-highlight-color: transparent;
        font-weight: 500;
    }

    .form_radio_btn {
        margin-left: 6vw;
    }


    .form_radio_btn input[type=radio]:checked+label {
        border: 1vw var(--hover-color) solid;

    }

    .input__block {
        display: flex;
        margin: 0 4vw 4vw 4vw;
        width: 100%;
        justify-content: space-evenly;
    }

    .button__style__center {
        margin-top: 3vw;
    }

    .third__block__about {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0vw;
        width: 100%;
    }

    .docum__text__style {
        font-size: 4vw;
        font-family: "Roboto";
        text-align: center;
        margin-bottom: 3vw;
    }

    .doc__main__block {
        display: flex;
        flex-direction: row;
        justify-content: none;
        /* border: 2px red solid; */
        width: 100%;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .document__style {
        width: 100%;
        margin-bottom: 3vw;


    }

    .document__style a {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        text-decoration: none;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-tap-highlight-color: transparent;
    }

    .document__style a span {
        /* border: 2px red solid; */

        font-size: 3.5vw;
        font-family: "Roboto";
        color: #000000;
        width: 90%;
        text-align: center;
    }

    .document__style a span:hover {
        color: #a0a0a0;
    }

    .icon__style {
        width: 15vw;
        height: 15vw;
    }

    .span__replace__color__main{
        font-size: 3.5vw;
    }

    .style__text__image{
        font-size: 3vw;
        width: 100%;
    }
}