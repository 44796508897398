.style__first__block{
    width: 100%;
    /* border: red solid 2px; */
    display: flex;
}

.container__with__icon {
    display: flex;
    justify-content: center;
}


.block__with__data{
    width: 50%;
    /* border: orange solid 2px; */
}

.style__images{
    width: 80%;
    height: 70%;
}

.text__style{
    font-size: 1vw;
    color: var(--main-color);
    font-weight: bold;
    width: 35vw;
    padding-left: 1.4vw;
}


@media (max-width: 770px) {
    .block__with__data{
        display: flex;
        width: 100%;
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* position: relative; */
        /* overflow: hidden; */
        /* bottom: 55px; */
    }

    .style__images{
        width: 100%;
        height: 100%;
    }

    .text__style{
        font-size: 4vw;
        color: var(--main-color);
        font-weight: bold;
        width: 95%;
        padding: 0vw;
        margin: 2vw;
    }

    .style__first__block{
        width: 100%;
        display: flex;
        box-sizing: border-box;
        margin-top: 2vw;
        box-shadow: 0px 0px 3vw -1vw #00000040;
        border: 0.9vw solid var(--main-color);
        border-radius: 4vw;
        padding:  3vw 0;
        /* padding: 1vw; */
    }

    .arrow__style{
        height: 40vw;
         width:40vw; 
         margin-top: 5vw;
    }
}