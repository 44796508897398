.block__title__style{
    display: flex;
    align-items: center;
}

.title__style{
    font-size: 1.2vw;
    color: var(--main-color);
    font-weight: 600;
}


.ellipse__style{
    border-style: solid;
     border-width: 0.4vw;
     border-color: var(--pink-color);
     border-radius: 3vw;
     width: 0.5vw;
     height: 0.5vw;
     margin-right: 1vw;
 }



@media(max-width: 3841px) {}

@media(max-width: 2561px) {}

@media(max-width: 1921px) {}


@media (max-width: 770px) {
    .block__title__style{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ellipse__style{
        border-width: 2vw;
        border-radius: 50vw;
        width: 2vw !important;
        height: 2vw !important;
        margin-right: 3vw;
    }

    .title__style{
        font-size: 5vw !important;
        color: var(--main-color);
    }

}


@media (max-width: 992px) {}


@media (max-width: 767px) {}


@media (max-width: 650) {}


@media (max-width: 480px) {}