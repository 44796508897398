.contact__style h1 {
    text-align: center;
    font-size: 1.2vw;
    color: #242424;
    font-family: "Roboto";
}

.contact__style h3 {
    font-size: 1.1vw;
    color: #242424;
    font-family: "Roboto";
}

.contact__style span {
    font-weight: 500;
    font-size: 1vw;
    color: #242424;
    font-family: "Roboto";
}

.adress__block__style {
    display: flex;
}

.wrapper__style {
    /* border: 2px red solid; */
    width: 60vw;
}

.contact__style {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.second__block__style {
    /* border: blue 2px solid; */
    margin-right: 1.5vw;
    cursor: pointer;
}

.adress__block__style {
    display: flex;
    justify-content: space-between;
    /* border: orange 2px solid */
}

.vk__style {
    display: flex;
    align-items: center;
    margin-bottom: 0.5vw;
}

.tg__style {
    display: flex;
    align-items: center;
    margin-bottom: 0.5vw;
}

.vk__style img {
    width: 1vw;
    margin-right: 0.5vw;
}

.tg__style img {
    width: 1vw;
    margin-right: 0.5vw;
}

.social__media:hover {
    color: var(--main-color);
}

.wrapper__style a {
    color: #242424;
    text-decoration: none;
}


@media(max-width: 3841px) {
    .contact__style h1 {
        font-size: 1.8vw;
    }

    .contact__style h3 {
        font-size: 1.2vw;
    }

    .contact__style span {
        font-size: 1vw;
    }
}

@media(max-width: 2561px) {
    .contact__style h1 {
        font-size: 1.9vw;
    }

    .contact__style h3 {
        font-size: 1.1vw;
    }

    .contact__style span {
        font-size: 0.9vw;
    }
}


@media(max-width: 1921px) {
    .contact__style h1 {
        font-size: 1vw;
    }

    .contact__style h3 {
        font-size: 1vw;
    }

    .contact__style span {
        font-size: 0.85vw;
    }
}


@media(max-width: 1280px) {
    .first__block span {
        font-size: 1vw;
    }

    .title__block span {
        font-size: 1vw;
    }

    .new__style__props {
        font-size: 0.9vw !important;
    }

    .text__span__style {
        font-size: 0.9vw;
    }
}


@media (max-width: 770px) {

    .contact__style {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .wrapper__style {
        /* border: 2px red solid; */
        width: 85%;
    }

    .contact__style h3 {
        font-size: 4vw;
        color: #242424;
        font-family: "Roboto";
    }

    .contact__style span {
        font-weight: 500;
        font-size: 3.2vw;
        color: #242424;
        font-family: "Roboto";
    }

    .second__block__style {
        /* border: blue 2px solid; */
        margin-right: 2vw;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-tap-highlight-color: transparent;
    }

    .wrapper__style h3 {
        font-weight: 500;
    }

    .vk__style {
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-tap-highlight-color: transparent;
        display: flex;
        align-items: center;
        margin-bottom: 3vw;
    }

    .tg__style {
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-tap-highlight-color: transparent;
        display: flex;
        align-items: center;
        margin-bottom: 3vw;
        /* border: 2px black solid; */
    }

    .vk__style img {
        width: 3.5vw;
        margin-right: 1.5vw;
    }

    .tg__style img {
        width: 3.5vw;
        margin-right: 1.5vw;
    }


}


@media (max-width: 992px) {}


@media (max-width: 767px) {}


@media (max-width: 650) {}


@media (max-width: 480px) {}