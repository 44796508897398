.style__for__big__data{
    font-size: 15vw !important;
    font-weight: 900 !important;
    color: #242424;
    margin: 0 !important;
    padding: 0 !important;
    /* border: 2px red solid; */
}

.mini__data__style{
    display: flex;
    flex-direction: row;
    margin: 0;
}

.text__for__mini{
    font-size: 6.5vw !important;
    font-weight: 900 !important;
    color: #242424;
    
}

.main__block{
    display: flex;
    /* border: 2px green solid; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.style__for__big__text{
    font-size: 2vw !important;
    font-weight: 900 !important;
    color: #242424;
    margin: 0 !important;
    padding: 0 !important;
    text-align: center;
    /* border: 2px red solid; */
}