.main__block__style h3 {
    font-family: "Roboto";
    font-size: 1.2vw;
    /* border: 2px red solid; */
    text-align: center;
    margin-bottom: 4vw;
}

.text__style {
    width: 7vw;
    height: 2vw;
    display: flex;
    align-items: center;
    font-size: 0.9vw;
}

.check__point__block {
    display: flex;
    flex-direction: column;;
    margin-top: 1vw;
   
}

.checkpoint__container{
    display: flex;
    flex-direction: row;
    margin-bottom: 2vw;
}

/* .check__point__block div {
    max-width: 33%;
}

.check__point__block div:nth-child(3n+1) {
    clear: left;
} */

.border__for__list {
    box-shadow: 0px 0px 1vw 0px #0000001A;
    border-radius: 1vw;
    width: 80%;
    height: 12vw;
    margin-top: 1vw;
    display: flex;
    flex-direction: column;
    padding: 0.5vw 0;
    overflow: auto;
    border: solid 0.2vw var(--main-color-border);
    /* border: 2px red solid; */
}

.border__for__list::-webkit-scrollbar {
    display: none !important;
}

.all__mark__block__border::-webkit-scrollbar {
    display: none !important;
}

.checkpoint__block span {
    font-size: 1.1vw;
}

.border__for__list span {
    margin: 0.2vw 1vw;
    font-size: 0.85vw;
    font-family: "Roboto";
}

.checkpoint__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* border: orange 2px solid; */
}

.all__mark__style {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2vw;
}

.all__mark__style span {
    font-size: 1.1vw;
    font-family: "Roboto";
}

.all__mark__block__border {
    box-shadow: 0px 0px 1vw 0px #0000001A;
    border-radius: 1vw;
    width: 50%;
    height: 8vw;
    margin-top: 1vw;
    display: flex;
    flex-direction: column;
    padding: 0.5vw 0;
    overflow: auto;
    border: solid 0.2vw var(--main-color-border);
    /* border: 2px red solid; */
}

.all__mark__block__border span {
    margin: 0.2vw 1vw;
    font-size: 0.85vw;
    font-family: "Roboto";
}

.list__comand__style {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 4vw;
    /* border: 2px red solid; */
}

.span__style {
    font-size: 1.1vw;
    margin-bottom: 1vw;
}

.list__dragan__style {
    border-radius: 1vw;
    padding: 0.5vw 1vw;
    margin-bottom: 1vw;
    background-color: white;
    cursor: move;
    width: 40%;
    text-align: center;
    font-size: 0.85vw;
}

.grade__team__style {
    display: flex;
    justify-content: space-evenly;
}

@media (max-width: 770px) {
    .main__block__style h3 {
        font-family: "Roboto";
        font-size: 4vw;
        /* border: 2px red solid; */
        text-align: center;
        margin-bottom: 7vw;
        font-weight: 500;
    }
    .check__point__block {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-top: 1vw;
        /* border: 2px green solid; */
    }
    .checkpoint__block {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        /* border: orange 2px solid; */
        margin-bottom: 5vw;
    }
    .checkpoint__block span {
        font-size: 4vw;
    }
    .border__for__list {
        box-shadow: 0px 0px 3vw -1vw #00000040;
        border-radius: 4vw;
        width: 100%;
        height: 40vw;
        margin-top: 2vw;
        display: flex;
        flex-direction: column;
        padding: 2vw 0;
        overflow: auto;
        /* border: 2px red solid; */
    }
    .border__for__list span {
        margin: 0vw 3vw;
        margin-bottom: 1vw;
        font-size: 3.5vw;
        font-family: "Roboto";
    }
    .all__mark__style {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0vw;
        margin-bottom: 5vw;
    }
    .all__mark__style span {
        font-size: 4vw;
        font-family: "Roboto";
    }
    .all__mark__block__border {
        box-shadow: 0px 0px 3vw -1vw #00000040;
        border-radius: 4vw;
        width: 100%;
        height: 40vw;
        margin-top: 2vw;
        display: flex;
        flex-direction: column;
        padding: 0.5vw 0;
        /* border: 2px red solid; */
    }
    .all__mark__block__border span {
        margin: 0vw 3vw;
        margin-bottom: 1vw;
        font-size: 3.5vw;
        font-family: "Roboto";
    }
    .span__style {
        font-size: 4vw;
        margin-bottom: 2vw;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-tap-highlight-color: transparent;
    }
    .list__comand__style {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 7vw;
        /* border: 2px red solid; */
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-tap-highlight-color: transparent;
    }
    .list__dragan__style {
        border-radius: 4vw;
        padding: 2.5vw 0vw;
        margin-bottom: 4vw;
        background-color: white;
        cursor: move;
        width: 100%;
        text-align: center;
        font-size: 3.5vw;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-tap-highlight-color: transparent;
    }
    .grade__team__style {
        display: flex;
        flex-direction: column;
        justify-content: none;
    }
}