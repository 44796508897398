.style__acordion{
    border: solid 0.2vw var(--main-color-border) !important;
    border-radius: 1vw !important;
    margin: 2vw 0vw;
    box-shadow: 0px 0px 0.5vw 0px #00000040 !important;
}
.style__summary{
    display: -webkit-box !important;
    /* color: red; */
}

.style__text__for__block{
    font-size: 1vw !important;
    font-family: 'Roboto' !important;
    white-space: nowrap;
    overflow: hidden; 
    text-overflow: ellipsis;
    margin: 0.3vw 1vw !important;
}

.style__text__for__block__case{
    font-size: 1vw !important;
    font-family: 'Roboto' !important;
    white-space: wrap;
    overflow: hidden; 
    text-overflow: ellipsis;
    margin: 1vw 1vw !important;
}

.style__presentation__block{
    width: 60vw;
    font-size: 1vw !important;   
    margin: 1.5vw 0 !important;
    
}



.style__column__name{
display: flex;
flex-direction: row;
justify-content: center;
margin-bottom: 2vw;
}

.style__text{
    width: 20%;
    text-align: center;
    font-size: 1vw;
    font-family: "Roboto";
    font-weight: 600;

}

.style__second__blocka{
    /* border: 2px red solid; */
}


.row__style{
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2vw 0vw;
}

.span__style__name{
    font-weight: 700;
}

@media (max-width: 770px) {
    .style__acordion{
        border: solid 1vw var(--main-color-border) !important;
        border-radius: 3vw !important;
        margin: 6vw 0;
        -webkit-box-shadow: 0px 0px 3vw -1vw #00000040 !important;
        box-shadow: 0px 0px 3vw -1vw #00000040 !important;
    }

    .style__text__for__block{
        font-size: 4vw !important;
        font-family: 'Roboto' !important;
        white-space: nowrap;
        overflow: hidden; 
        text-overflow: ellipsis;
        margin: 0vw 0vw !important;
    }

    .style__text__for__block__case{
        font-size: 4vw !important;
        font-family: 'Roboto' !important;
        white-space: wrap;
        overflow: hidden; 
        text-overflow: ellipsis;
        margin: 2vw 0vw !important;
    }

    .style__column__name{
    margin-top: 2vw;
    margin-bottom: 2vw;
    }

    .style__text{
    width: 20%;
    text-align: center;
    font-size: 3.5vw;
    font-family: "Roboto";
    font-weight: 600;
    }

    .style__presentation__block{
        width: 100%;
        font-size: 3vw !important;   
        margin: 2vw 0 !important;
    }

    .button__style__frame{
        /* border: 2px red solid; */
        margin-top: 3vw;
        display: flex;
        justify-content: center;
    }

    .span__style__name{
     font-weight: 700;
    }

    .row__style{
       margin-top: 6vw;
    }
}