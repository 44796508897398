.style__div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1vw;
}


.style__text__block{
    width: 20%;
    text-align: center;
    font-size: 1vw;
    font-family: "Roboto";
    white-space: wrap;
    overflow: hidden; 
    text-overflow: ellipsis;
}

.line__spliter{
    width: 80%;
    background-color: grey;
    height: 0.1vw;

}

.style__column__name{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 2vw;
    }

.style__text{
        width: 20%;
        text-align: center;
        font-size: 1vw;
        font-family: "Roboto";
        font-weight: 600;
    
    }


@media (max-width: 770px) {
    .style__text__block{
        width: 100%;
        margin: 1vw 0.25vw;
        white-space: wrap;
        overflow: hidden; 
        text-overflow: ellipsis;
        text-align: center;
        font-size: 3.5vw;
        font-family: "Roboto";
    }
    

    .style__text{
        width: 100%;
        text-align: center;
        font-size: 3.5vw;
        font-family: "Roboto";
        font-weight: 600;
        }

    .style__column__name{
        margin-top: 2vw;
        margin-bottom: 2vw;
        }
    
}
