.react-ui-1dsbxui {
    border: none;
    box-shadow: none;
}

/* REMOVE TIME STYLE */
.MuiInput-underline:before,
.MuiInput-underline::after {
    display: none;
}

.MuiInput-underline {
    width: 100%;
    /* opacity: 0; */
}

.TimePicker-header-4,
.TimePicker-placeholder-23,
.TimePicker-header-21 {
    background: var(--main-color) !important;
}

.Clock-pointer-14,
.Clock-innerDot-34 {
    background-color: var(--main-color) !important;
}

.Clock-outerDot-18,
.Clock-outerDot-35 {
    border: 16px solid var(--main-color) !important;

}

.Clock-innerDot-17,
.Clock-pointer-31 {
    background-color: var(--main-color) !important;
}

.MuiButton-label {
    font-size: 0.85vw;
    color: var(--main-color);
    font-family: "Roboto";
}

.MuiPaper-rounded {
    border-radius: 1.5vw !important;
}

@media (max-width: 770px) {
    .MuiButton-label {
        font-size: 3.5vw;
        color: var(--main-color);
        font-family: "Roboto";
    }

    .MuiPaper-rounded {
        border-radius: 5vw !important;
    }

    .MuiInputBase-input {
        color: #949494 !important;
    }
}