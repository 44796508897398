.li__style {
    margin-bottom: 2vw !important;
}

/* .events__create__style span {
    font-size: 0.9vw; */
    /* border: 2px violet solid; */
    /* font-family: "Roboto";
} */

.events__create__style {
    /* border: 2px red solid; */
}

.text__style {
    width: 7vw;
    height: 2vw;
    display: flex;
    align-items: center;
}

.h3__style {
    font-size: 1.2vw;
    font-family: "Roboto";
    font-weight: 500;
    display: flex;
    justify-content: center;
    margin-bottom: 7vw;
}

.main__block__mark {
    display: flex;
    justify-content: space-evenly;
    box-shadow: 0px 0px 0.53vw 0px #00000040;
    background-color: white;
    padding: 1.5vw;
    border-radius: 1vw;
    margin-top: 2vw;
}

.repo__and__grade__style {
    display: flex;
    align-items: center;
    margin-top: 2vw;
}

.repo__style {
    display: flex;
    margin-right: 2vw;
    width: 50%;
}

.text__style {
    width: 7vw;
    height: 2vw;
    display: flex;
    align-items: center;
}

.text__style span {
    font-size: 0.9vw;
    font-family: "Roboto";
}

.border__block {
    margin-top: 3vw;
    box-shadow: 0px 0px 0.53vw 0px #00000040;
    padding: 1.5vw;
    border-radius: 1vw;
}

.table__style {
    text-align: center;
    width: 100% !important;
    font-size: 0.9vw;
    font-weight: 500;
}

.table__style tr {
    margin: 2vw 0;
}

.table__style th {
    font-weight: 500;
}

.markWork__code_review{
    display: flex;
    flex-direction: column;
    gap: 1vw;
    /* border: 2px red solid; */
}

.markWork__code_review span{
    font-size: 0.9vw;
    color: #BCBCBC;
}

@media (max-width: 770px) {

    .markWork__code_review span{
        font-size: 3.2vw;
        color: #BCBCBC;
    }

    .table__style td {
        width: 60%;
        padding: 2vw 1vw;
        /* border: 2px red solid; */
    }
    .text__style__table {
        font-size: 3.5vw;
        font-weight: 500;
    }
    .border__block {
        margin-top: 7vw;
        padding: 2vw 3vw;
        box-shadow: 0px 0px 3vw -1vw #00000040;
        border: 0.9vw solid var(--main-color);
        border-radius: 4vw;
    }
    .table__style {
        display: flex;
        justify-content: space-evenly;
        text-align: center;
        width: 100% !important;
        font-size: 3.5vw;
        font-weight: 500;
        /* border: 2px red solid; */
    }
    /* tr:nth-child(odd) {
        background-color: #f2f2f2;
    } */
    .repo__style {
        display: flex;
        width: 100%;
        margin-right: 0vw;
        /* border: 2px orange solid; */
    }
    .repo__and__grade__style {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 7vw;
        /* border: 2px red solid; */
    }
    .h3__style {
        font-size: 4.5vw;
        font-family: "Roboto";
        font-weight: 500;
        display: flex;
        justify-content: center;
        margin-bottom: 7vw;
    }
    /* .events__create__style span {
        font-size: 3vw; */
        /* border: 2px violet solid; */
        /* font-family: "Roboto";
    } */
    .text__style {
        width: 12vw;
        height: 10vw;
        display: flex;
        align-items: center;
        /* border: 2px violet solid; */
    }
    .main__block__mark {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        box-shadow: 0px 0px 3vw -1vw #00000040;
        background-color: white;
        /* padding: 1.5vw; */
        border-radius: 4vw;
        margin-top: 2vw;
    }
    .unborder__style {
        border-top: transparent 0px solid !important;
    }
    .text__name__style{
        margin: 1vw 0;
    }

    .text__style__table {
        font-size: 3.5vw;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        
    }
}