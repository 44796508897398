.main__style__for__block{
    padding: 0 3vw;
    /* border: 2px red solid; */
}


.ellipse__style{
   border-style: solid;
    border-width: 0.4vw;
    border-color: #FFD8D1;
    border-radius: 3vw;
    width: 1vw;
    height: 1vw;
    margin-right: 1vw;
}

.title__style{
    font-size: 2vw;
 color: var(--main-color);
}

.block__title__style{
    display: flex;
    align-items: center;
}



.block__info__style{
    /* border: 2px red solid; */
    display: flex;
    justify-content: space-around;
    margin-bottom: 3vw;
}

.img__style{
    width: 5vw;
    /* margin-top: 1vw; */
    /* border: 2px red solid; */
}

.h5__style{
    text-align: center;
    color: var(--main-color);
    font-size: 1.3vw;
    margin: 0;
    padding: 0;
}


.block__with__info{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-color: var(--main-color);
    border-width: 0.3vw;
    border-style: solid;
    border-radius: 1vw;
    box-shadow: 0px 0px 0.5vw 0px #00000040;
    width: 23%;
    height: 9vw;
    transition: all 5s;
    position: relative;
    left: 0;
    bottom: 0;
    z-index: 1;
    transition: all 500ms;
    transform-origin: center;
    user-select: none;

}

.block__with__info:hover {
    transform: scale(1.07);
    transition-duration: 0.5s;
    z-index: 2;
}


.animation_ux .left {
    bottom: 3vw;
    right: 5vw;
    transform: scale(0.3) rotate(-45deg);
    transition: all 800ms;
}


.animation_ux .right {
    bottom: 3vw;
    left: 5vw;
    transform: scale(0.3) rotate(45deg);
    transition: all 800ms;
}

.animation_ux .bottom {
    bottom: 3vw;
    transform: scale(0.1);
    transition: all 800ms;
}

.animation_ux .top {
    top: 3vw;
    transform: scale(0.1);
    transition: all 800ms;
}



@media (max-width: 770px) {

    .main__style__for__block{
        padding: 0;
        border-radius: 4vw;
        /* box-shadow: 0px 0px 3vw -1vw #00000040; */
        
        /* border: 2px red solid; */
    }
    .block__title__style{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ellipse__style{
         border-width: 2vw;
         border-radius: 50vw;
         width: 3vw;
         height: 3vw;
         margin-right: 3vw;
     }

     .title__style{
        font-size: 6vw;
     color: var(--main-color);
    }

    .block__with__info{
        justify-content: center;
        border-color: var(--main-color);
        border-width: 1vw;
        border-style: solid;
        border-radius: 4vw;
        box-shadow: 0px 0px 3vw -1vw #00000040;
        width: 45%;
        height: auto;
        padding: 10px 0;
    }


    .img__style{
        width: 10vw;
        /* margin-top: 1vw; */
        /* border: 2px red solid; */
    }

    .h5__style{
        text-align: center;
        color: var(--main-color);
        font-size: 4vw;
        margin: 0;
        padding: 0;
    }
     

}

