.events__create__style li {
    display: flex;
    list-style: none;
    margin-bottom: 1vw;
}

.li__style {
    margin-bottom: 1vw !important;
    display: flex;
    flex-direction: column;
}

.events__create__style span {
    font-size: 0.9vw;
    /* border: 2px violet solid; */
    font-family: "Roboto";
}

.events__create__style {
    /* border: 2px red solid; */
}

.text__style {
    width: 7vw;
    height: 2vw;
    display: flex;
    align-items: center;
}

.h3__style {
    font-size: 1.2vw;
    font-family: "Roboto";
    font-weight: 500;
    display: flex;
    justify-content: center;
    margin-bottom: 7vw;

}

.style__title title {
    border: 0px transparent solid;
    color: red;
}

.input__stol__style {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 2px solid red; */
}

.span__stol__style {
    font-size: 0.80vw !important;
    font-family: "Roboto";
    font-weight: 500;
    margin-bottom: 0.7vw;
}

.sidebar-title {
    font-size: 5vw;
}


.createOccasion__checkpointNum{
    width: 100%;
    border-radius: 0.4vw;
    outline: none;
    box-shadow: 0px 0px 0.12vw 0px #000040;
    border: 0.1vw solid white;
    font-size: 1vw;
    padding: 0;
    margin: 0;
    padding: 0 1vw;
    box-sizing: border-box;
    height: 100%;
}

.createOccasion__checkpointNum:focus-within{
    border: 0.1vw solid var(--hover-color);
}

.inputBlock__lable__focus{
    color: #808080;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.8vw;
    padding: 0px;
    display: block;
    transform-origin: left top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 32px);
    position: absolute;
    left: 0px;
    top: 0px;
    transform: translate(0.77vw, -0.5vw) scale(0.75);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    z-index: 2;
    pointer-events: auto;
    user-select: none;
    background-color: white;
    padding: 0 0.4vw;
}

.select__container {
    /* box-shadow: 0px 0px 0.12vw 0px #000040; */
    width: 100%;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: var(--height-input);
}

.create__map{
    text-decoration: none;
    font-size: 0.8vw;
    margin-top: 0.5vw;
    color: #b2b2b2;
}

@media (max-width: 770px) {
    .events__create__style li {
        display: flex;
        list-style: none;
        margin: 0;
        /* border: 2px red solid; */
    }

    .h3__style {
        font-size: 4vw;
        font-family: "Roboto";
        font-weight: 500;
        display: flex;
        justify-content: center;
        margin-bottom: 7vw;
    }

    .events__create__style ul {
        padding: 0;
    }

    .li__style {
        margin-bottom: 0vw !important;
    }

    .input__stol__style {
        display: flex;
        flex-direction: column;
        align-items: center;
        /* border: 2px solid red; */
        margin-top: 2vw;
    }

    .time__style {
        font: none;
        font-size: 10.5vw !important;
    }
}