.container {
    color: black;
    min-height: 1.5vw;
    border-radius: 1.5vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-shadow: 0px 0px 0.5vw 0px #00000040;
    box-shadow: 0px 0px 0.5vw 0px #00000040; 
    border: solid 0.2vw var(--main-color-border);
    padding: 0.6vw;
    background-color: white;
    /* border: 2px solid red; */
}



@media (max-width: 770px) {
    .container {
        color: black;
        height: auto;
        border-radius: 4vw;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-shadow: 0px 0px 3vw -1vw #00000040;
        box-shadow: 0px 0px 3vw -1vw #00000040;
        border: 0.9vw solid var(--main-color);
        padding: 0;
        background-color: white;
        margin-bottom: 4vw;

    }
}


@media (max-width: 992px) {}


@media (max-width: 767px) {}


@media (max-width: 650) {}


@media (max-width: 480px) {}