.li__style {
    margin-bottom: 2vw !important;
}

.events__create__style span {
    /* font-size: 0.9vw; */
    /* border: 2px violet solid; */
    font-family: "Roboto";
}

.events__create__style {
    border: 2px ref solid;
}

.text__style {
    width: 7vw;
    height: 2vw;
    display: flex;
    align-items: center;
}

.h3__style {
    font-size: 1.2vw;
    font-family: "Roboto";
    font-weight: 500;
    display: flex;
    justify-content: center;
    margin-bottom: 3vw;
}

.main__block__mark {
    display: flex;
    justify-content: space-evenly;
    box-shadow: 0px 0px 0.53vw 0px #00000040;
    background-color: white;
    padding: 1.5vw;
    border-radius: 1vw;
    margin-top: 2vw;
}

.input__block {
    gap: 0 2.5vw;
    display: flex;
    justify-content: center;
    margin-bottom: 6vw;
}


/*Стиль radio btn */

.form_radio_btn {
    margin-left: 0vw;
}

.form_radio_btn input[type=radio] {
    display: none;
}

.form_radio_btn label {
    display: inline-block;
    cursor: pointer;
    border-radius: 0.4vw;
    user-select: none;
    font-family: "Roboto";
    font-size: 0.9vw;
    box-shadow: 0px 0px 0.3vw 0px #00000040;
    background-color: white;
    padding: 0.4vw 1.5vw;
    font-weight: 500;
    border: 0.2vw transparent solid;
}


/* Checked */

.form_radio_btn input[type=radio]:checked+label {
    border: 0.2vw var(--hover-color) solid;
}


/* Disabled */

.form_radio_btn input[type=radio]:disabled+label {}

.repo__and__grade__style {
    display: flex;
    align-items: center;
    margin-top: 2vw;
}

.repo__style {
    display: flex;
    margin-right: 2vw;
    /* border: 2px red solid; */
    width: 50%;
}

.text__style {
    width: 7vw;
    height: 2vw;
    display: flex;
    align-items: center;
}

.text__style span {
    font-size: 0.9vw;
    font-family: "Roboto";
}

.border__block {
    margin-top: 3vw;
    box-shadow: 0px 0px 0.53vw 0px #00000040;
    padding: 1.5vw;
    border-radius: 1vw;
}

.table__style {
    text-align: center;
    
    width: 100% !important;
    font-size: 0.9vw;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
}

.table__style tr {
    margin: 0vw 0;
}

.border__block {
    margin-top: 3vw;
    box-shadow: 0px 0px 0.53vw 0px #00000040;
    padding: 1.5vw;
    border-radius: 1vw;
}

.table__style td {
    /* display: flex;
    flex-direction: row;
justify-content: space-around; */
    /* padding: 0vw 6vw; */
    /* border: 2px red solid; */
}

.table__style th {
    font-weight: 500;
}

@media (max-width: 770px) {
    .input__block{
        flex-wrap: wrap;
        width: 100%;
        gap: 3vw;
    }
    .table__style td {
        width: 60%;
        padding: 2vw 3vw;
        /* border: 2px red solid; */
    }
    .text__style__table {
        font-size: 3.5vw;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        
    }
    .text__name__style{
        margin: 1vw 0;
    }
    .border__block {
        margin-top: 7vw;
        padding: 2vw 3vw;
        box-shadow: 0px 0px 3vw -1vw #00000040;
        border: 0.9vw solid var(--main-color);
        border-radius: 4vw;
    }
    .table__style {
        display: flex;
        justify-content: space-around;
        text-align: center;
        width: 100% !important;
        font-size: 3.5vw;
        font-weight: 500;
        /* border: 2px red solid; */
    }
    .repo__style {
        display: flex;
        width: 100%;
        margin-right: 0vw;
        /* border: 2px orange solid; */
    }
    .repo__and__grade__style {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 7vw;
        /* border: 2px red solid; */
    }
    .main__block__mark {
        display: flex;
        flex-direction: column;
        box-shadow: 0px 0px 3vw -1vw #00000040;
        background-color: white;
        padding: 1.5vw;
        border-radius: 4vw;
        margin-top: 2vw;
    }
    .form_radio_btn label {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 3vw;
        user-select: none;
        font-family: "Roboto";
        font-size: 3.5vw;
        box-shadow: 0px 0px 3vw -1vw #00000040;
        background-color: white;
        width:auto;
        height: 6vw;
        font-weight: 500;
        border: 1vw transparent solid;
        white-space: nowrap;
    }
    .form_radio_btn input[type=radio]:checked+label {
        border: 1vw var(--hover-color) solid;
    }
    .h3__style {
        font-size: 4.5vw;
        font-family: "Roboto";
        font-weight: 500;
        display: flex;
        justify-content: center;
        margin-bottom: 3vw;
    }
}