.main__block__style {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 2px violet solid; */
}



.example__wrapper {
    display: flex;
    gap: 1vw;
    margin-bottom: 1vw;
    justify-content: center;
    /* border: 2px red solid; */
    width: 100%;
}

.example__wrapper .coordinates input {
    width: 100%;
    font-size: 130%;
    border-radius: 7px;
}

.coordinates input {
    width: 2.5vw;
}

.occasion__style {
    display: flex;
    align-items: none;
    list-style: none;
    margin-bottom: 1vw;
    width: 100%;
}

.block__style {
    display: flex;
    justify-content: start;
    margin-bottom: 1vw;
    width: 100%;
    /* border: 2px red solid; */
}

.text__style {
    width: 7vw;
    height: 2vw;
    display: flex;
    align-items: center;
}

.text__style span {
    font-size: 0.9vw;
}

.h3__style {
    font-size: 1.2vw;
    font-family: "Roboto";
    font-weight: 500;
    display: flex;
    justify-content: center;
    margin-bottom: 7vw;
}

.btn__style {
    margin-left: 1vw;
}

.certificate__choiceCord{
    /* border: 2px green solid; */
}

.coordinates{
    width: 100%;
    /* border: 2px blue solid; */
}

.coordinates__wrapper {
    display: flex;
    gap: 1vw;
    margin-bottom: 1vw;
    justify-content: space-around;
    width: 100%;
}

.coordinates__container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    /* flex-direction: column; */
    width: 100%;
}

@media (max-width: 770px) {

    .occasion__style {
        display: flex;
        flex-direction: column;
        list-style: none;
        align-items: center;
    }


    .h3__style {
        font-size: 4vw;
        font-family: "Roboto";
        font-weight: 500;
        display: flex;
        justify-content: center;
        margin-bottom: 7vw;
    }

    .style__margin {
        margin-bottom: 2vw;
        /* border: 2px red solid; */
        width: 100%;
    }

    .btn__style {
        margin: 0;
        width: 100%;
        display: flex;
        justify-content: center;

    }

    .shadow__box__style {
        box-shadow: 0px 0px 3vw -1vw #00000040;
        padding: 4vw 2vw;
        padding-bottom: 3vw;
        border-radius: 4vw;
        margin-bottom: 4vw;

    }
}