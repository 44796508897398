.style__second__block{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-right: 5vw;
    /* border: 2px green solid; */
}



/* .style__second__block:not(:first-child) {
    position: relative;
    right: 5vw;
} */


.main__style__for__block{
    padding: 0 0vw;
    /* border: 2px red solid; */
}

.ellipse__style{
   border-style: solid;
    border-width: 0.4vw;
    border-color: #FFD8D1;
    border-radius: 3vw;
    width: 1vw;
    height: 1vw;
    margin-right: 1vw;
}

.title__style{
    font-size: 2vw;
 color: var(--main-color);
}

.block__title__style{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1vw;
}

.block__with__info{
    display: flex;
    flex-direction: row;
    align-items: center;
    border-color: var(--main-color);
    border-width: 0.3vw;
    border-style: solid;
    border-radius: 1vw;
    box-shadow: 0px 0px 0.5vw 0px #00000040;
    width: 77.4vw;
    height: 9vw;
}

.block__info__style{
    /* border: 2px red solid; */
    display: flex;
    justify-content: space-around;
    margin-bottom: 0vw;
}

.img__style{
    width: 4vw;
    margin-left: 1vw;
    /* transition: all 5s; */

    /* margin-top: 1vw; */
    /* border: 2px red solid; */
}

.h2__style{
    text-align: center;
    font-weight: bold;
    color: var(--main-color);
    font-size: 4vw;
    margin: 0;
    padding: 0;
}

.h5__style{
    text-align: center;
    color: var(--main-color);
    font-size: 1.6vw;
    margin: 0;
    padding: 0;
}

.h6__style{
    text-align: center;
    color: var(--main-color);
    font-size: 1.4vw;
    margin: 0;
    padding: 0;
}


.stat__style{
    display: flex;
    flex-direction: column;
    /* border: 2px red solid; */
    width: 100%;
    height: 100%;
    margin: 1vw;
    justify-content: center;
    align-items: center;

}

.stat__with__icon{
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 2px red solid; */
    width: 13vw;
   
}


.anim__style__text{
    display: flex;
justify-content: right;
width: 100%;
}

.first__block__style {
    display: flex;
    justify-content: left;
    margin-top: 2vw;
    margin-left: 4vw;
}


.scan__qr{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    right: 3vw;
}

.commandir__scan{
    font-size: 1.5vw;
    font-weight: 800;
    margin-bottom: 2vw;
}