.container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    border-radius: 0.5vw;
    box-shadow: 0px 0px 0.12vw 0px #000040;
    margin: 0;
    border: none;
    padding: 1vw 0;
}

.textLocation__legendStyle{
    margin-left: 0.7vw;
    padding: 0 0.3vw;
    color: #808080;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.61vw;
    z-index: 2;
    background-color: white;
}

.textLocation__buttonStyle{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 0.4vw;
    user-select: none;
    font-family: "Roboto";
    font-size: 0.9vw;
    box-shadow: 0px 0px 0.3vw 0px #00000040;
    background-color: white;
    padding: 0.5vw 2vw;
    font-weight: 500;
    border: 0.2vw transparent solid;
}

/* fieldset div {
    display: grid;
    justify-content: center;
} */