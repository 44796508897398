.mark__button__style {
    width: 2vw;
    height: 2vw;
    box-shadow: 0px 0px 0.212vw 0px #00000040;
    border-radius: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mark__button__style span {
    font-size: 1vw;
    font-family: "Roboto";
    font-weight: 500;
    color: white;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


@media (max-width: 770px) {
    .mark__button__style {
        width: 15vw;
        height: 15vw;
        box-shadow: 0px 0px 3vw -1vw #00000040;
        border-radius: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mark__button__style span {
        font-size: 5vw !important;
        font-family: "Roboto";
        font-weight: 500;
        color: white;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}