.wrapper {
    padding: 15vw 0 0 0;
    position: relative;
    top: 2vw;
    overflow: auto;
}


.fixedContainer {
    position: fixed;
    background-color: white;
    box-sizing: border-box;
    justify-content: center;
    width: 100%;
    padding: 0 5%;
    left: 0;
    top: 5%;
    z-index: 2;
    padding-top: 10%;
}

.inputContainer {
    width: 100%;
    box-shadow: 0px 0px 2vw -0.7vw #00000040;
    border-radius: 2vw;
}

.inputContainer input {
    width: 100%;
    box-sizing: border-box;
    padding: 2.5vw;
    border-radius: 2vw;
    border: none;
}

.closeTeamChoice {
    width: 10vw;
    height: 10vw;
    color: var(--main-color);
}

.close__style{
    display: flex;
    justify-content: end;
    margin-bottom: 4vw;
    /* border: 2px red solid; */
}

.containerInputBlock {
    padding: 2vw;
    border-radius: 2vw;
    border: 1vw transparent solid;
    margin: 1vw 0;
}

.container {
    display: grid;
    gap: 0.5vw 0;
    margin-top: 0vw;
    position: relative;
    margin-top: 40%;
}


.form_radio_btn input[type=radio] {
    display: none;
}


.form_radio_btn label {
    display: inline-block;
    cursor: pointer;
    border-radius: 2vw;
    user-select: none;
    font-family: "Roboto";
    font-size: 3.5vw;
    box-shadow: 0px 0px 3vw -1vw #00000040;
    background-color: white;
    width: 25vw;
    height: 7vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1vw transparent solid;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
    font-weight: 500;
    border: 1vw solid var(--main-color);
}


.input__block {
    display: flex;
    margin: 0 0;
    width: 100%;
    justify-content: space-between;
    margin: 6vw 0;
}