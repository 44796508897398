.text__style {
    width: 7vw;
    height: 2vw;
    display: flex;
    align-items: center;
}

.container {
    width: 100%;
    display: grid;
    justify-content: center;
}

.container h3 {
    text-align: center;
    margin-bottom: 7vw;
    font-weight: 500;
    font-size: 1.2vw;
}

.text__style span {
    font-size: 0.9vw;
    font-family: "Roboto";
}

.repo__and__grade__style {
    display: flex;
    align-items: center;
    margin-top: 1vw;
}

.repo__style {
    display: flex;
    margin-right: 2vw;
    width: 100%;
}

.main__block__info {
    display: flex;
    flex-direction: column;
}

@media (max-width: 770px) {

    .container h3 {
        text-align: center;
        margin-bottom: 7vw;
        font-weight: 500;
        font-size: 4vw;
    }


    .repo__and__grade__style {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0vw;
        /* border: 2px red solid; */
    }

    .repo__style {
        display: flex;
        width: 100%;
        margin-right: 0vw;
        /* border: 2px orange solid; */
    }

    .main__block__info {
     width: 100%;
     display: flex;
     flex-direction: column;
     gap: 10px;
    }

    .container{
        grid-template-columns: 1fr;
    }

    .grade__style {}
}