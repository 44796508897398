.text__style {
  font-size: 1.2vw;
  color: var(--main-color);
  margin: 0;
  padding: 0;
  /* border: 2px red solid; */
}

.description__style {
  font-size: 0.8vw;
  color: var(--main-color);
  margin: 0;
  padding: 0;
}

.img__style {
  background-position: center center;
  background-size: cover;
  width: 10vw;
  /* border: 2px red solid; */
}

.text__style__blue {
  font-size: 1.2vw;
  color: #fff;
  margin: 0;
  padding: 0;
  /* border: 2px red solid; */
}

.description__style__blue {
  font-size: 0.8vw;
  color: #fff;
  margin: 0;
  padding: 0;
}


@media (max-width: 770px) {
  .main__block__wrapper {
    margin: 0;
    margin-bottom: 10vw;
  }

  .text__style__blue {
    font-size: 5vw;
    color: #fff;
    margin: 0;
    padding: 0;
    /* border: 2px red solid; */
  }

  .text__style {
    font-size: 5vw;
    color: var(--main-color);
    margin: 0;
    padding: 0;
    /* border: 2px red solid; */
  }

  .description__style__blue {
    font-size: 4vw;
    color: #fff;
    margin: 0;
    padding: 0;
  }

  .description__style {
    font-size: 4vw;
    color: var(--main-color);
    margin: 0;
    padding: 0;
  }

  .img__style {
    background-position: center center;
    background-size: cover;
    width: 40vw;
    /* border: 2px red solid; */
  }


}