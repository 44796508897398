.container {
    background-color: rgba(135, 133, 133, 0.5);
    width: 28vw;
    height: 17vw;
    border-radius: 1vw;

    overflow-x: hidden;
    position: relative;
    /* animation: animation-image-loader 1s linear infinite; */
}


.container .flare {
    position: absolute;
    top: 0;
    height: 100%;
    width: 45px;
    transform: skewX(-45deg);
    animation: animation-image-loader;
    left: -150%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.4));
    animation: animation-image-loader 3s infinite linear;
}


@keyframes animation-image-loader {
    0% {
        left: -30px;
        margin-left: 0px;
        opacity: 0.5;
    }

    30% {
        left: 110%;
        margin-left: 80px;
        opacity: 0.2;
    }

    100% {
        left: 110%;
        margin-left: 80px;
        opacity: 0.1;
    }
}

@media(max-width: 770px) {
    .container {
        background-color: rgba(135, 133, 133, 0.5);
        width: 85vw;
        height: 60vw;
        border-radius: 4vw;

        overflow-x: hidden;
        position: relative;
        /* animation: animation-image-loader 1s linear infinite; */
    }
}