.image {
    z-index: 2;
    position: relative;
    max-width: 100%;
    border-radius: 1vw;
    object-fit: cover;
}

.center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageContainer {
    height: 15vw;
    box-shadow: 0px 0px 0.12vw 0px #000000;
    border-radius: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 60vw;
    overflow: hidden;
}

.events__create__style li {
    display: flex;
    list-style: none;
    margin-bottom: 1vw;
}

.li__style {
    margin-bottom: 2vw !important;
}

.events__create__style span {
    font-size: 0.9vw;
    /* border: 2px violet solid; */
    font-family: "Roboto";
}

.events__create__style {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 2px red solid; */
}

.text__style {
    width: 7vw;
    height: 2vw;
    display: flex;
    align-items: center;
}

.h3__style {
    font-size: 1.2vw;
    font-family: "Roboto";
    font-weight: 500;
    display: flex;
    justify-content: center;
    margin-bottom: 7vw;

}

.style__title title {
    /* border: 0px transparent solid; */
    /* color: red; */
}

.drop__down__style {
    width: 20vw;
    height: 13vw;
    box-shadow: 0px 0px 0.12vw 0px #000000;
    border-radius: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3vw;
}

.drop__down__style span {
    text-align: center;
    font-size: 1.1vw;
    font-family: "Roboto";
    color: rgb(138, 138, 138);
    font-weight: 500;
    line-height: 2vw;
}

.down__block__style {
    display: flex;
    width: 60vw;
    /* border: 2px red solid; */

}


@media (max-width: 770px) {
    .h3__style {
        font-size: 4vw;
        font-family: "Roboto";
        font-weight: 500;
        display: flex;
        justify-content: center;
        margin-bottom: 7vw;
    }

    .events__create__style ul {
        padding: 0;
        width: 100%;
    }

    .down__block__style {
        display: flex;
        width: 100%;
        /* border: 2px red solid; */
        justify-content: center;
    }

    .drop__down__style {
        width: 100%;
        height: 15vw;
        box-shadow: 0px 0px 3vw -1vw #00000040;
        border-radius: 4vw;
        display: flex;
        align-items: center;
        justify-content: center;
        /* border: 2px green solid; */
        margin: 0;
    }

    .drop__down__style span {
        text-align: center;
        font-size: 3.5vw;
        font-family: "Roboto";
        color: rgb(122, 122, 122);
        font-weight: 500;
        line-height: 6vw;
    }

    .imageContainer {
        width: 100%;
        height: 35vw;
        box-shadow: 0px 0px 3vw -1vw #00000040;
        border-radius: 4vw;
    }

}