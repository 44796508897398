.red_text {
    font-size: 1.2vw;
    margin-right: 10px;
    font-family: "Roboto";
}

.text_description {
    font-size: 1.2vw;
    font-family: "Roboto";
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media(max-width: 3841px) {
    .red_text {
        font-size: 1.2vw;
        margin-right: 10px;
    }

    .text_description {
        font-size: 1.2vw;
    }
}

@media(max-width: 2561px) {
    .red_text {
        font-size: 1.1vw;
        margin-right: 10px;
    }

    .text_description {
        font-size: 1.1vw;
    }
}

@media(max-width: 1921px) {
    .red_text {
        font-size: 1vw;
        margin-right: 10px;
    }

    .text_description {
        font-size: 1vw;
    }
}

@media(max-width: 770px) {
    .red_text {
        font-size: 4vw;
        margin-right: 1vw;
        margin-left: 2vw;
    }

    .text_description {
        font-size: 3.5vw;
        text-align: center;
        margin-right: 2vw;
    }
}