.box__style span {
    font-size: 0.65vw;
    font-family: "Roboto";
    font-weight: 500;
    text-align: center;
    color: #242424;
}

.box__style img {
    width: 1.1vw;
    height: 1.1vw;
}

.box__style {
    width: 2.5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* border: 2px red solid; */
}

@media (max-width: 770px) {
    .box__style span {
        font-size: 2vw;
        font-family: "Roboto";
        font-weight: 500;
        text-align: center;
        color: #242424;
        z-index: 90;
        /* border: 2px blue solid; */
    }

    .box__style img {
        width: 4vw;
        height: 4vw;
        z-index: 90;
        margin-bottom: 1vw;
        /* border: 2px orange solid; */
    }

    .box__style {
        width: 2vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 90;
        /* border: 2px red solid; */
        margin-left: 3vw;
    }
}