.wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    transition: 0.6s;
    display: flex;
    align-items: center;
    /* border: red dashed 2px; */
}

.drop__down__style {
    width: 100%;
    /* box-shadow: 0px 0px 0.12vw 0px #000000; */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.drop__down__style span {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 20px;
    /* border: 2px red solid; */
}

.blureMainContainer {
    top: 3vw;
    left: 0;
    position: fixed;
    width: 100vw;
    height: 100%;
    display: none;
    /* z-index: 1; */
    /* border: 2px red solid; */

}

.drop__down__style span {
    text-align: center;
    font-size: 1.1vw;
    font-family: "Roboto";
    color: rgb(156, 156, 156);
    font-weight: 500;
    line-height: 2vw;
    /* border: 2px red solid; */
}

.down__block__style {
    position: relative;
    z-index: 9;
    width: 100%;
}

@media (max-width: 770px) {

    .blureMainContainer {
        top: 12vw;
        height: 100%;
    }

    .down__block__style {
        display: flex;
        width: 100%;
        /* border: 2px red solid; */
        justify-content: center;
    }


    .drop__down__style {
        width: 100%;
        border-radius: 4vw;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
    }

    .label__style {
        width: 100%;
    }

    .drop__down__style span {
        text-align: center;
        font-size: 4vw !important;
        font-family: "Roboto";
        color: rgb(156, 156, 156);
        font-weight: 500;
        line-height: 6vw;
    }

}