.tags_input_container{
    box-shadow: 0px 0px 0.12vw 0px #000040;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 0.4vw;
    /* width: min(80vw, 600px); */
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: .5em;
    width: 100%;
    height: 100%;
}

.tag_item{
    background-color: rgb(218, 216, 216);
    display: flex;
    padding: .4em .65em;
    justify-content: center;
    align-items: center;
    border-radius: 1vw;
    height: 15px;
}
.tag_item .close{
    height: 15px;
    width: 15px;
    background-color: rgb(48, 48, 48);
    color: #fff;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: .5em;
    font-size: var(--font-size-small);
    cursor: pointer;
}

.tags_input{
    flex-grow: 1;
    padding: 0;
    border: none;
    outline: none;
    font-size: var(--font-size-small);
    height: 100%;
}

.inputBlock__lable{
    color: #808080;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: var(--font-size-small);
    /* line-height: 1.4375em;
    letter-spacing: 0.00938em; */
    line-height: calc((var(--height-input) / 2) + calc(var(--font-size-small) / 2));
    padding: 0px;
    display: block;
    transform-origin: left top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
    position: absolute;
    left: 1.05vw;
    /* top: 0.6vw; */
    transform: translate(0, 0) scale(1);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    z-index: 2;
    pointer-events: none;
}

.inputBlock__lable__focus{
    color: #808080;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.8vw;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0px;
    display: block;
    transform-origin: left top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 32px);
    position: absolute;
    left: 0px;
    top: 0px;
    transform: translate(0.77vw, -0.5vw) scale(0.75);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    z-index: 2;
    pointer-events: auto;
    user-select: none;
    background-color: white;
    padding: 0 0.4vw;
}


.inputTag__wrapper{
    width: calc(100%);
    /* display: inline-flex; */
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: var(--height-input);
}

@media (max-width: 770px) {
    .inputBlock__lable{
       
        font-size: 3vw;
        left: 3vw;
        top: 3.5vw;
        transform: translate(0, 0) scale(1);
    }
    
    .inputBlock__lable__focus{
        font-size: 4vw;
        transform: translate(3vw, -2vw) scale(0.75);
        padding: 0 3vw;
    }

}