.h3__style {
  font-size: 1.2vw;
  font-family: "Roboto";
  font-weight: 500;
  display: flex;
  justify-content: center;
  margin-bottom: 3vw;
}

@media (max-width: 770px) {
  .h3__style {
    font-size: 4.5vw;
    font-family: "Roboto";
    font-weight: 500;
    display: flex;
    justify-content: center;
    margin-bottom: 3vw;
  }
}