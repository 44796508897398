.profile__image {
    width: 8vw;
    height: 8vw;
    box-shadow: 0px 0px 0.3vw 0px #00000040;
    border: 0.2vw solid var(--main-color);
    border-radius: 100vw;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.profile__image img {
    width: 7vw;
    height: 7vw;
    border-radius: 100vw;
}

@media (max-width: 770px) {
    .profile__image {
        width: 15vw !important;
        height: 15vw !important;
        box-shadow: 0px 0px 3vw -1vw #00000040;
        border: 0.9vw solid var(--main-color);
        border-radius: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        margin-top: 4vw;
    }

    .profile__image img {
        width: 13vw !important;
        height: 13vw !important;
        border-radius: 100vw;
    }
}