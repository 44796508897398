
/* Размеры */
:root {
    /* --sidebar-width-active: 300px;
    --sidebar-width: 70px; */

    /* анимации */
    /* --logo-transition: 0.5s; */

    /* Шрифты */
    --font-size-small: 14pt;
    --font-size-middle: 18pt;

    /* Цвета */
    --main-color: #4783ed;
    --main-color-border: #4783ed;
    --hover-color: #81a8ec;

    --pink-color:#FFD8D1;

    --red-color:#ff2600;
}


@media (max-width: 770px) {
    :root {
    /* Шрифты */
    --font-size-small: 18pt;
    --font-size-middle: 24pt;
    }
}

/* @media (max-width: 1280px) {
    :root {
    --sidebar-width-active: 250px;
    --sidebar-width: 50px;
    }
}
 */