.wrapper {
    box-shadow: 0px 0px 0.1vw 0px #000000;
    border-radius: 1vw;
    display: grid;
    justify-content: start;
    align-items: center;
    transition: 700ms;
    padding-top: 0.9vw;
    padding-bottom: 0;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    bottom: 1vw;
    background-color: rgb(255, 255, 255);
    max-height: 14.35vw;
}

.style__input__block {
    height: 2.0vw;
    align-items: center;
    display: flex;
    padding-left: 9.3px;
    padding-right: 9.3px;
}

.style__input__block:hover {
    color: var(--main-color);
}

.style__input__block__text h4 {
    font-family: "Roboto";
    font-size: 0.8vw;
    font-weight: normal;
    margin: 0;
    padding: 0;
    display: inline;
    /* ----- */
    text-overflow: ellipsis;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

@keyframes animation_visibility_block {
    from {
        display: none;
    }
    to {
        display: flex;
    }
}

@media(max-width: 1280px) {
    .wrapper {
        padding-top: 10px;
        padding-bottom: 0;
    }
    .style__input__block__text h4 {
        font-size: 1vw;
    }
}

@media(max-width: 770px) {
    .style__input__block {
        height: 7.175vw;
    }
    .style__input__block__text h4 {
        font-size: 3.5vw;
    }
    .wrapper {
        max-height: 36vw;
        box-shadow: 0px 0px 3vw -1vw #00000040;
        border-radius: 4vw;
        gap: 2vw 0;
        padding: 5.1vw 9.3px 0 9.3px;
        bottom: 5vw;
        box-sizing: border-box;
    }
}