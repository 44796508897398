.react-toggle {
    touch-action: pan-x;

    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 0.318vw;
    margin: -0.318vw;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 0.318vw;
}

.react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
}

.react-toggle-track {
    width: 2.615vw;
    height: 1.272vw;
    padding: 0;
    border-radius: 1.6vw;
    background-color: #4D4D4D;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
    background-color: var(--main-color);
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: var(--main-color);
}

.react-toggle-track-check {
    position: absolute;
    width: 0.75vw;
    height: 0.55vw;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 0.424vw;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle-track-x {
    position: absolute;
    width: 0.53vw;
    height: 0.53vw;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 0.53vw;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
    opacity: 0;
}

.react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 0.07vw;
    left: 0.318vw;
    width: 1.155vw;
    height: 1.155vw;
    border: 0.318vw solid #4D4D4D;
    border-radius: 50%;
    background-color: #FAFAFA;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
    left: 1.4vw;
    border-color: var(--main-color);
}

.react-toggle--focus .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 0.318vw 0.212vw transparent;
    -moz-box-shadow: 0px 0px 0.318vw 0.212vw transparent;
    box-shadow: 0px 0px 0.212vw 0.318vw transparent;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 0.53vw 0.53vw transparent;
    -moz-box-shadow: 0px 0px 0.53vw 0.53vw transparent;
    box-shadow: 0px 0px 0.53vw 0.53vw transparent;
}

.react-toggle-track-check svg {
    width: 0.6vw !important;
    height: 0.6vw !important;
}

.react-toggle-track-x svg {
    width: 0.5vw !important;
    height: 0.5vw !important;
}

@media (max-width: 770px) {
    .react-toggle-screenreader-only {
        border: 0;
        clip: rect(0 0 0 0);
        height: 0.318vw;
        margin: -0.318vw;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 0.318vw;
    }

    .react-toggle--disabled {
        cursor: not-allowed;
        opacity: 0.5;
        -webkit-transition: opacity 0.25s;
        transition: opacity 0.25s;
    }

    .react-toggle-track {
        width: 15.69vw;
        height: 7.632vw;
        padding: 0;
        border-radius: 9.6vw;
        background-color: #4D4D4D;
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: #000000;
    }

    .react-toggle--checked .react-toggle-track {
        background-color: var(--main-color);
    }

    .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: var(--main-color);
    }

    .react-toggle-track-check {
        position: absolute;
        width: 4.5vw;
        height: 3.3vw;
        top: 0px;
        bottom: 0px;
        margin-top: auto;
        margin-bottom: auto;
        line-height: 0;
        left: 2.544vw;
        opacity: 0;
        -webkit-transition: opacity 0.25s ease;
        -moz-transition: opacity 0.25s ease;
        transition: opacity 0.25s ease;
    }

    .react-toggle--checked .react-toggle-track-check {
        opacity: 1;
        -webkit-transition: opacity 0.25s ease;
        -moz-transition: opacity 0.25s ease;
        transition: opacity 0.25s ease;
    }

    .react-toggle-track-x {
        position: absolute;
        width: 3.18vw;
        height: 3.18vw;
        top: 0px;
        bottom: 0px;
        margin-top: auto;
        margin-bottom: auto;
        line-height: 0;
        right: 3.18vw;
        opacity: 1;
        -webkit-transition: opacity 0.25s ease;
        -moz-transition: opacity 0.25s ease;
        transition: opacity 0.25s ease;
    }

    .react-toggle--checked .react-toggle-track-x {
        opacity: 0;
    }

    .react-toggle-thumb {
        transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
        position: absolute;
        top: 0.32vw;
        left: 1, 908vw;
        width: 6.93vw;
        height: 6.93vw;
        border: 0.5vw solid #4D4D4D;
        border-radius: 50%;
        background-color: #ffffff;

        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        -webkit-transition: all 0.25s ease;
        -moz-transition: all 0.25s ease;
        transition: all 0.25s ease;
    }

    .react-toggle--checked .react-toggle-thumb {
        left: 8.4vw;
        border-color: var(--main-color);
    }

    .react-toggle--focus .react-toggle-thumb {
        -webkit-box-shadow: 0px 0px 1.908vw 1.272vw transparent;
        -moz-box-shadow: 0px 0px 1.908vw 1.272vw transparent;
        box-shadow: 0px 0px 1.272vw 1.908vw transparent;
    }

    .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
        -webkit-box-shadow: 0px 0px 3.18vw 3.18vw transparent;
        -moz-box-shadow: 0px 0px 3.18vw 3.18vw transparent;
        box-shadow: 0px 0px 3.18vw 3.18vw transparent;
    }

    .react-toggle-track-check svg {
        width: 3.6vw !important;
        height: 3.6vw !important;
    }

    .react-toggle-track-x svg {
        width: 3.0vw !important;
        height: 3.0vw !important;
    }
}