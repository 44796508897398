:root {
    /* Шрифты */
    --font-size-very-small: 8pt;
    --font-size-small: 10pt;
    --font-size-small-12: 12pt;
    --font-size-medium: 15pt;
    --font-size-large: 20pt;
    --font-size-big: 24pt;
    --font-size-very-big: 35pt;
    --font-size-ultra-big: 55pt;

    --height-input: 40px;
    /* --content-width: 1440px; */
    /* --header-heigth: 100px; */
  
    /* ProfileSidebar */
    /* --sidebar-profile-width: 300px; */
    /* Burger */
    /* --burger-width: 30px; */
    /* --burger-wrapper-width: 85%; */
  }
  
  @media only screen and (max-width: 1440px) {
    :root {
      --content-width: 1024px;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    :root {
      --content-width: 768px;
    }
  }
  
  @media only screen and (max-width: 768px) {
    :root {
      --content-width: 100%;
      --content-horizontal-padding: 5%;
      --height-input: 50px;
    }
  }
  
  @media only screen and (max-width: 480px) {
    :root {}
  }
  
  /* Для маленьких экранов (мобильные устройства) */
  @media (max-width: 600px) {
    :root {
      --font-size-very-small: 6pt;
      --font-size-small: 8pt;
      --font-size-small-12: 10pt;
      --font-size-medium: 12pt;
      --font-size-large: 16pt;
      --font-size-big: 20pt;
      --font-size-very-big: 30pt;
      --font-size-ultra-big: 45pt;
    }
  }
  
  /* Для средних экранов (планшеты) */
  @media (min-width: 601px) and (max-width: 1024px) {
    :root {
      --font-size-very-small: 7pt;
      --font-size-small: 9pt;
      --font-size-small-12: 11pt;
      --font-size-medium: 13pt;
      --font-size-large: 18pt;
      --font-size-big: 22pt;
      --font-size-very-big: 32pt;
      --font-size-ultra-big: 50pt;
    }
  }
  
  /* Для больших экранов (десктопы) */
  @media (min-width: 1025px) {
    :root {
      --font-size-very-small: 8pt;
      --font-size-small: 10pt;
      --font-size-small-12: 12pt;
      --font-size-medium: 15pt;
      --font-size-large: 20pt;
      --font-size-big: 24pt;
      --font-size-very-big: 35pt;
      --font-size-ultra-big: 55pt;
    }
  }