.block__with__mark {
    /* border: 2px violet solid; */
    display: grid;
    grid-template-areas:
        "button_0 button_1"
        "button_2 button_3";
    gap: 1vw;
}


.block__with__mark div:nth-child(1) {
    grid-area: button_0;

}

.block__with__mark div:hover {
    background-color: var(--main-color) !important;
    cursor: pointer;
}

.block__with__mark div:nth-child(2) {
    grid-area: button_1;
}

.block__with__mark div:nth-child(3) {
    grid-area: button_2;
}

.block__with__mark div:nth-child(4) {
    grid-area: button_3;
}

.style__span {
    font-size: 0.9vw;
    font-family: "Roboto";
    font-weight: 500;
    text-align: center;
    margin-bottom: 0vw;
    /* border: black 2px solid; */
    height: 4vw;
}

.style__block {
    /* border: 2px red solid; */
    width: 7vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}


@media (max-width: 770px) {
    .style__block {
        /* border: 2px red solid; */
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-bottom: 5vw;
        padding-top: 5vw;
    }

    .style__span {
        font-size: 4vw !important;
        font-family: "Roboto";
        font-weight: 500;
        text-align: center;
        margin: 0;
        margin-right: 5vw;
        /* border: black 2px solid; */
        width: 50%;
        height: auto;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .block__with__mark {
        /* border: 2px violet solid; */
        display: grid;
        grid-template-areas:
            "button_0 button_1"
            "button_2 button_3";
        gap: 3vw;
    }

}