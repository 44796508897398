.wrapper {
    width: 100%;
    padding: 2.5vw 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-radius: 4vw;
    box-sizing: border-box;
    padding: 2.5vw;
    box-shadow: 0px 0px 2vw -0.7vw #00000040;
    justify-content: space-between;
    align-items: center;
    background-color: white;
}

.wrapper span {
    font-size: 85%;
    padding-left: 1%;
    color: #555454;
}

.style__input__block__image {
    display: flex;
    align-items: center;
    width: 2vw;
    justify-content: end;
    height: 100%;
    user-select: none;
}

.style__input__block__image img {
    display: block;
    padding-right: 0.3vw;
    width: 1vw;
}

.inputBlock__lable{
    color: #808080;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1vw;
    /* line-height: 1.4375em;
    letter-spacing: 0.00938em; */
    padding: 0px;
    display: block;
    transform-origin: left top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
    position: absolute;
    left: 1.05vw;
    top: 0.6vw;
    transform: translate(0, 0) scale(1);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    z-index: 2;
    pointer-events: none;
    /* border: 2px red solid; */
}

.inputBlock__lable__focus{
    color: #808080;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.8vw;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0px;
    display: block;
    transform-origin: left top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 32px);
    position: absolute;
    left: 0px;
    top: 0px;
    transform: translate(0.77vw, -0.5vw) scale(0.75);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    z-index: 2;
    pointer-events: auto;
    user-select: none;
    background-color: white;
    padding: 0 0.4vw;
}

.choice__wrapp{
    display: inline-flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}


@media (max-width: 770px) {
    .style__input__block__image {
        display: flex;
        align-items: center;
        width: 3vw;
        justify-content: end;
    }

    .style__input__block__image img {
        display: block;
        padding-right: 1vw;
        width: 3vw;
    }

    .wrapper {
        width: 100%;
        border-radius: 2vw;
        height: 11.5vw;
        box-shadow: 0px 0px 2vw -0.7vw #00000040;
        border: solid 0.2vw #bbbbbb;
    }

    .wrapper span {
        font-size: 3.8vw;
        color: #212121;
    }

    
    .inputBlock__lable{
       
        font-size: 3vw;
        left: 3vw;
        top: 4vw;
        transform: translate(0, 0) scale(1);
    }
    
    .inputBlock__lable__focus{
        font-size: 4vw;
        transform: translate(3vw, -2vw) scale(0.75);
        padding: 0 3vw;
    }


}