.single__chart {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.single__chart span {
    text-align: center;
    font-size: 0.9vw;
    font-family: "Roboto";
    font-weight: 500;
    width: 85%;
}

.circular__chart {
    display: block;
    margin: 10px auto;
   width: 10vw;
}

.circle__bg {
    fill: none;
    stroke: #eee;
    stroke-width: 3.8;
}

.circle {
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
}

.circular__chart.blue .circle {
    stroke: var(--main-color);
}

.percentage {
    fill: #1d1d1d;
    font-family: sans-serif;
    font-size: 9px;
    text-anchor: middle;
}

.percentage__small {
    fill: #1d1d1d;
    font-family: sans-serif;
    font-size: 4px;
    text-anchor: middle;
}

.card__second__block {
    display: grid;
    grid-template-columns: 20% 80%;
}

@keyframes progress {
    0% {
        stroke-dasharray: 0 100;
    }
}

@media (max-width: 770px) {

    .circular__chart {
       width: 30vw;
    }

    .single__chart span {
        font-size: 3vw;
       
    }
    }
