/* #app { height: 100% }
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

body {
  background: #fff;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

html,
body {
  position: relative;
  height: 100%;
}

#app {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.swiper {
  width: 15vw;
  height: 20vw;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.1vw;
  font-size: 1.2vw;
  font-weight: bold;
  color: #fff;
}

.swiper-slide:nth-child(1n) {
  background-color: #fff;
  border: 0.4vw var(--main-color) solid;
}

.swiper-slide:nth-child(2n) {
  background-color: #fff;
  border: 0.4vw var(--main-color) solid;
}

.swiper-slide:nth-child(3n) {
  background-color: #fff;
  border: 0.4vw var(--main-color) solid;
}

.swiper-slide:nth-child(4n) {
  background-color: #fff;
  border: 0.4vw var(--main-color) solid;
}




@media (max-width: 770px) {
  .swiper {
    width: 50vw;
    height: 20vw;
  }

  .swiper-slide:nth-child(1n) {
    background-color: #fff;
  border: 2vw var(--main-color) solid;
    border-radius: 4vw;
  }
  
  .swiper-slide:nth-child(2n) {
    background-color: #fff;
    border: 2vw var(--main-color) solid;
    border-radius: 4vw;
  }
  
  .swiper-slide:nth-child(3n) {
    background-color: #fff;
  border: 2vw var(--main-color) solid;
    border-radius: 4vw;
  }
  
  .swiper-slide:nth-child(4n) {
    background-color: #fff;
    border: 2vw var(--main-color) solid;
    border-radius: 4vw;
  }
  }