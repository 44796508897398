.main__block__style {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 2px violet solid; */
}


.occasion__style {
    display: flex;
    align-items: none;
    list-style: none;
    margin-bottom: 1vw;
}

.block__style {
    display: flex;
    justify-content: start;
    margin-bottom: 1vw;
    /* border: 2px red solid; */
}

.text__style {
    width: 7vw;
    height: 2vw;
    display: flex;
    align-items: center;
}

.text__style span {
    font-size: 0.9vw;
}

.h3__style {
    font-size: 1.2vw;
    font-family: "Roboto";
    font-weight: 500;
    display: flex;
    justify-content: center;
    margin-bottom: 7vw;
}

.btn__style {
    margin-left: 1vw;
}

.style__chart{
    width: 30vw;
    height: 30vw; 
    display: flex;
    justify-content:center;
    align-items: center; 
    overflow: visible;
    z-index: 100;

}