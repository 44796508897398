.qrCodeContainer {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
}

.qrCode {
    display: -webkit-box;
    -webkit-box-pack: center;
}

.qrCodeGenerator {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    /* border: 2px red solid; */
}