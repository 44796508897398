.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.Mui-readOnly.MuiInputBase-readOnly.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
   height: 2vw;
   font-size: 1vw;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeMedium.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.MuiFormLabel-filled.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeMedium.MuiInputLabel-outlined.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root{
    font-size: 1vw;
    color: #242424;
    font-family: 'Roboto' !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before{
    background-color: rgb(0 0 0 / 0%) !important;
}


.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color:var(--main-color-border) !important;
   
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border-radius: 0.5vw !important;
    cursor: pointer !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    cursor: pointer !important;
}

@media (max-width: 770px) {
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.Mui-readOnly.MuiInputBase-readOnly.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
        height: 9vw;
        font-size: 4vw;
     }

     .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeMedium.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.MuiFormLabel-filled.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeMedium.MuiInputLabel-outlined.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root{
        font-size: 3.5vw;
        color: #242424;
        font-family: 'Roboto' !important;
    }

    .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
        border-radius: 2vw !important;
    }
    }