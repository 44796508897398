.main__block__auth__style {
    width: 23vw;
    height: auto;
    box-shadow: 0px 0px 1.2vw 0px #00000040;
    border: 0.2vw solid var(--main-color);
    border-radius: 2vw;
    padding-bottom: 1vw;
}

.main__block__auth__style span {
    font-size: 1vw;
    font-family: "Roboto";
    font-weight: 500;
    margin-top: 0.8vw;
}


.main__block__auth__style h3 {
    font-size: 1.1vw;
    font-family: "Roboto";
    font-weight: 500;
    display: flex;
    justify-content: center;
}

.main__block__auth__style {
    background-color: white;
}

.auth__button__style {
    display: flex;
    justify-content: space-around;
    /* border: 2px red solid; */
    height: 7vw;
}

.auth__button__style div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.size__img__style {
    width: 2vw;
    height: 2vw;
}

@media(max-width: 770px) {
    .main__block__auth__style {
        width: 100%;
        height: auto;
        padding-bottom: 3vw;
        box-shadow: 0px 0px 3vw -1vw #00000040;
        border: 0.9vw solid var(--main-color);
        border-radius: 4vw;
    }

    .main__block__auth__style span {
        font-size: 3.5vw;
        font-family: "Roboto";
        font-weight: 500;
        margin-top: 2vw;
    }

    .main__block__auth__style h3 {
        font-size: 4vw;
        font-family: "Roboto";
        font-weight: 500;
        display: flex;
        justify-content: center;
        height: 20%;
        /* border: 2px orange solid; */
        margin: 0;
        align-items: center;

    }

    .auth__button__style {
        display: flex;
        padding: 0;
        justify-content: space-around;
        /* border: 2px red solid; */
        height: 80%;
    }

}