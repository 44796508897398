.profile-container {
  width: 100%;
  display: grid;
  justify-content: center;
}

.profile-image {
  width: 100%;
  display: flex;
  justify-content: center;
}

.css-i4bv87-MuiSvgIcon-root {
  width: 1.3vw !important;
  height: 1.3vw !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: var(--main-color) !important;
}

.css-ahj2mt-MuiTypography-root {
  font-size: 1vw !important;
}

@media (max-width: 770px) {
  .profile-container {
    display: flex;
    flex-direction: column;
  }
  .css-i4bv87-MuiSvgIcon-root {
    width: 5vw !important;
    height: 5vw !important;
  }
}