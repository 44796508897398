.certificate-wrapper {
  width: 100%;
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
  grid-template-areas: "title title" "settings certifucate" "create-buytton create-buytton";
}

.certificate__title {
  grid-area: title;
}

.certificate__container {
  grid-area: settings;
  width: 100%;
}

.image-delete {
  cursor: pointer;
  z-index: 3;
  top: 0px;
  right: 20px;
  position: absolute;
  font-size: 20pt;
  color: var(--red-color);
}
.image-delete span {
  border: 2px solid var(--main-color-border);
}

.certificate-generation-container {
  grid-area: certifucate;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: end;
  position: sticky;
  top: 3.445vw;
}

.certificate-generation-container__image-preview {
  position: relative;
}
.certificate-generation-container__image-preview span {
  position: absolute;
  z-index: 3;
}
.certificate-generation-container__image-preview img {
  z-index: 2;
  position: relative;
}

.certificate-generation-container__qrcode {
  position: absolute;
  z-index: 3;
  border: 2px red dashed;
  padding: 0;
}
.certificate-generation-container__qrcode .hash-code {
  text-align: center;
  width: 100%;
  font-size: var(--font-size-small);
}
.certificate-generation-container__qrcode canvas {
  width: 100%;
}

.button-position {
  margin-top: 3vw;
  display: flex;
  justify-content: center;
  grid-area: create-buytton;
}

@media (max-width: 770px) {
  .button-position {
    margin-top: 6vw;
    display: flex;
    justify-content: center;
  }
}