.certificate {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: start;
}

.certificate__contentFirst {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: 100%;
    /* border: 2px blue solid; */
}

.certificate__contentFirst__input {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.certificate__contentSecond {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1vw;

    /* border: 2px violet solid; */
}

.temp__img {
    width: 6vw;
    height: 6vw;
    border-radius: 100vw;
}

.temp__img__style {
    width: 6vw;
    height: 6vw;
    border-radius: 100vw;
    padding: 0.5vw;
    border: 0.2vw var(--main-color-border) solid;
    object-fit: cover;
}

.certificate__contentSecond__img {
    width: 100;
    max-width: 100%;
    height: auto;
    cursor: pointer;
    max-height: 600px;
}

.certificate__contentSecond__open img {
    width: 0.9vw;
}

.certificate__contentSecond__open {
    text-decoration: none;
    display: flex;
    align-items: center;
    color: var(--main-color);
    gap: 0.5vw;
}

.container__style {
    width: 20vw;
    height: 30vw;
}

.cert__wrapper {
    width: 30vw;
    height: auto;
    display: flex;
    justify-content: center;
}

.diploma__secondBlock {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vw;
}

.diploma__secondBlock__img{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1vw;
}

@media (max-width: 770px) {
    .certificate {
        flex-direction: column;
    }

    .certificate__contentFirst__input {
        width: 100%;
        gap: 0;
    }

    .temp__img {
        width: 100px;
        height: 100px;
        border: 2px red solid;
        border-radius: 100px;
    }

    .certificate__contentFirst {
        gap: 10vw;
    }

    .certificate__contentSecond__open img {
        width: 4vw;
    }

    .certificate__contentSecond__open {
        gap: 3vw;
    }

    .container__style {
        width: 80vw;
        height: 90vw;
    }

    .cert__wrapper {
        width: 80vw;
        height: auto;
    }

    .temp__img__style {
        width: 20vw;
        height: 20vw;
        border-radius: 100vw;
        padding: 2vw;
        border: 1vw var(--main-color-border) solid;
        object-fit: cover;
    }

    .diploma__secondBlock {
        flex-wrap: wrap;
    }

    .diploma__secondBlock__img{
        flex-wrap: wrap;
        gap: 4vw;
        margin-bottom: 5vw;
    }
}