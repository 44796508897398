.case__style h3 {
    font-size: 1.3vw;
    color: #242424;
    font-family: "Roboto";
    /* border: 2px red solid; */
}

.main__block {
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* border: 2px red solid; */
    gap: 1vw
}

.main__wrapps {
    display: flex;
    align-items: start;
    /* border: 2px red solid; */
    width: 100%;
    height: auto;
    overflow: auto;
}

.case__style {
    border-radius: 1.2vw;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    border: solid 0.2vw var(--main-color-border);
    box-shadow: 0px 0px 0.5vw 0px #00000040;
    background-color: white;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-bottom: 1vw;
    width: 55%;
    /* overflow: auto; */
    /* border: 2px green solid; */
}

.second__block {
    width: 60%;
    height: auto;
    /* border: blue 2px solid; */
    padding-right: 3%;
    padding-left: 3%;
}

.line__block {
    background-color: #D5D5D5;
    width: 0.05vw;
    height: 100%;
    /* border: violet 2px solid; */
}

.first__block {
    width: 40%;
    height: 100%;
    padding: 0;
    /* border: orange 2px solid; */
}

.first__block ul {
    padding-left: 2vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    height: 100%;
    /* border: 2px solid blue; */
    margin: 0;
}


.first__block li {
    list-style: none;
    display: flex;
    align-items: start;
    justify-content: left;
    margin-bottom: 0.371vw;
}

.first__block span {
    font-size: 1.08vw;
    font-family: "Roboto";
    font-weight: 500;
    color: #242424;
}

.first__block img {
    height: 1.07vw;
    width: 1.07vw;
    margin-right: 0.5vw;
    margin-top: 0.1vw;
}

.description__block__style {
    /* border: green 2px solid; */
    line-height: 1.2vw;
    height: 50%;
}

.expected_result {
    /* border: 2px purple solid; */
    margin-top: 1vw;
    height: 50%;
    line-height: 1.2vw;
}

.title__block {
    margin-bottom: 0.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title__block img {
    height: 1.07vw;
    width: 1.07vw;
    margin-right: 0.5vw;
}

.title__block span {
    font-size: 1.08vw;
    font-family: "Roboto";
    font-weight: 500;
}

.text__span__style {
    font-size: 1.00vw;
    font-family: "Roboto";
    font-weight: 500;
}

.new__style__props {
    font-size: 1.00vw !important;
    font-weight: 500 !important;
    
}

.first__block a {
    text-decoration: none;
    /* color: red; */
}

.first__block a:hover {
    color: var(--main-color);
}

.case__title{
    width: 96%;
    display: flex;
    justify-content: start;
    /* border: 2px red solid; */
}

.caseChecker__comment{
    height: 75%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vw;
    justify-content: end;
    /* border: 2px red solid; */
    box-sizing: border-box;
}

.caseChecker__statusBtn{
    display: flex;
    flex-direction: column;
    width: 45%;
    justify-content: space-between;
    /* border: 2px red solid; */
}

.input__block {
    gap: 0 2.5vw;
    display: flex;
    justify-content: space-between;
    /* border: 2px green solid; */
    height: 25%;
    width: 100%;

}


.form_radio_btn {
    margin-left: 0vw;
}

.form_radio_btn input[type=radio] {
    display: none;
}

.form_radio_btn label {
    display: inline-block;
    cursor: pointer;
    border-radius: 0.4vw;
    user-select: none;
    font-family: "Roboto";
    font-size: 0.8vw;
    box-shadow: 0px 0px 0.3vw 0px #00000040;
    background-color: white;
    padding: 0.4vw 0.5vw;
    font-weight: 500;
    border: 0.2vw transparent solid;
    white-space: nowrap;
}


/* Checked */

.form_radio_btn input[type=radio]:checked+label {
    border: 0.2vw var(--hover-color) solid;
}


/* Disabled */

.form_radio_btn input[type=radio]:disabled+label {}

.repo__and__grade__style {
    display: flex;
    align-items: center;
    margin-top: 2vw;
}


@media(max-width: 3841px) {

    .case__style {
        height: auto;

    }

    .first__block span {
        font-size: 1.05vw;
    }

    .title__block span {
        font-size: 1.05vw;
    }

    .new__style__props {
        font-size: 1vw !important;
    }

    .text__span__style {
        font-size: 1vw;
    }
}


@media(max-width: 2561px) {


    .first__block span {
        font-size: 1vw;
    }

    .title__block span {
        font-size: 1vw;
    }

    .new__style__props {
        font-size: 0.9vw !important;
    }

    .text__span__style {
        font-size: 0.9vw;
    }
}


@media(max-width: 1921px) {


    .first__block span {
        font-size: 0.9vw;
    }

    .title__block span {
        font-size: 0.9vw;
    }

    .new__style__props {
        font-size: 0.8vw !important;
    }

    .text__span__style {
        font-size: 0.8vw;
    }
}

@media(max-width: 1280px) {
    .first__block span {
        font-size: 1vw;
    }

    .title__block span {
        font-size: 1vw;
    }

    .new__style__props {
        font-size: 0.9vw !important;
    }

    .text__span__style {
        font-size: 0.9vw;
    }
}


@media (max-width: 770px) {
    .case__style {
        border-radius: 4vw;
        height: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        box-shadow: 0px 0px 3vw -1vw #00000040;

        background-color: white;
       border: 0.9vw solid var(--main-color);
        margin-bottom: 4vw;
    }

    .case__style h3 {
        font-size: 3.8vw;
        color: #242424;
        font-family: "Roboto";
        font-weight: 500;
        margin: 2vw 0;
        /* border: 2px red solid; */
    }

    .main__wrapps {
        display: flex;
        flex-direction: column;
        /* border: 2px red solid; */
        width: calc(100% - 6vw);
        height: auto;
        padding: 0 3vw;
    }

    .first__block {
        width: 100%;
        padding: 0;
        /* border: orange 2px solid; */
    }

    .first__block ul {
        padding-left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        height: auto;
        /* border: 2px solid blue; */
        margin: 0;
    }


    .first__block li {
        list-style: none;
        display: flex;
        align-items: start;
        justify-content: left;
        margin-bottom: 2vw;
        /* border: 2px violet solid; */
    }

    .first__block span {
        font-size: 3.4vw !important;
        font-family: "Roboto";
        font-weight: 500;
        color: #242424;
    }

    .first__block img {
        height: 4vw;
        width: 4vw;
        margin-right: 1.5vw;
        margin-top: 0.1vw;
    }

    .new__style__props {
        font-size: 3.2vw !important;
        font-weight: 500 !important;
    }

    .line__block {
        background-color: #DDDDDD;
        width: 100%;
        height: 0.1vw;
        /* border: violet 2px solid; */
        margin: 2vw 0;
    }

    .second__block {
        width: 100%;
        height: auto;
        /* border: blue 2px solid; */
        padding-right: 3%;
        padding-left: 3%;
    }


    .description__block__style {
        /* border: green 2px solid; */
        height: auto;
        line-height: 4.5vw;
    }

    .title__block {
        margin-bottom: 0.5vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .title__block img {
        height: 3.5vw;
        width: 3.5vw;
        margin-right: 0.5vw;
    }

    .title__block span {
        font-size: 3.4vw !important;
        font-family: "Roboto";
        font-weight: 500;
    }

    .text__span__style {
        font-size: 3.2vw !important;
        font-family: "Roboto";
        font-weight: 500;
    }

    .expected_result {
        /* border: 2px purple solid; */
        height: auto;
        line-height: 4.5vw;
        margin-top: 2vw;
        margin-bottom: 2vw;
    }

    .main__block {
        flex-direction: column;
        margin-bottom: 4vw;
    }

}


@media (max-width: 992px) {}


@media (max-width: 767px) {}


@media (max-width: 650) {}


@media (max-width: 480px) {}