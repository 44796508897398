.profile__image {
    width: 8vw;
    height: 8vw;
    box-shadow: 0px 0px 0.3vw 0px #00000040;
    border-radius: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    overflow: hidden;
    border: solid 0.2vw var(--main-color-border);
    cursor: pointer;
}

.profile__image img {
    width: 7vw;
    height: 7vw !important;
    border-radius: 100vw;
    box-sizing: border-box;
}

@media (max-width: 770px) {
    .profile__image {
        width: 25vw;
        height: 25vw;
        box-shadow: 0px 0px 3vw -1vw #00000040;
        border: 0.9vw solid var(--main-color);
        border-radius: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        margin-top: 4vw;
    }

    .profile__image img {
        width: 22vw;
        height: 22vw !important;
        border-radius: 100vw;
    }
}