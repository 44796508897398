html,
body {
    margin: 0;
    height: 100%;
}

.rc-tooltip-inner {
    border: transparent 0.1vw solid !important;
    box-shadow: 0px 0px 0.15vw 0px var(--main-color);
    padding: 0.5vw 0.6vw !important;
    min-height: 1vw !important;
    border-radius: 0.5vw !important;
    text-align: center !important;
}

.rc-tooltip {
    background-color: transparent !important;
}

.rc-tooltip-arrow {
    /* box-shadow: 0px 0px 0.15vw 0px var(--main-color); */
    border-top-color: #9bcaff !important;

}

#root {
    height: 100%;
}