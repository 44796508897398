.text__style__block{
    font-size: 1vw;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: normal; 
    word-wrap: break-word; 
    text-align: center;
    min-height:2.4vw; 
    /* border: 2px solid red; */
}


@media (max-width: 770px) {
    .text__style__block{
        margin-bottom: 3vw;
        font-size: 4vw;
     
    }

    

    }

