.main__block__style{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.block__title__style{
  display: flex;
  align-items: center;
}

.ellipse__style{
  border-style: solid;
   border-width: 0.4vw;
   border-color: #FFD8D1;
   border-radius: 3vw;
   width: 1vw;
   height: 1vw;
   margin-right: 1vw;
}

.title__style{
  font-size: 2vw;
color: var(--main-color);
}

.main__style__for__block{
  padding: 0 3vw;
  /* border: 2px red solid; */
}

@media (max-width: 770px) {
  .main__style__for__block{
      padding: 0;
      border-radius: 4vw;
      /* box-shadow: 0px 0px 3vw -1vw #00000040; */

      /* border: 2px red solid; */
  }
  .block__title__style{
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .ellipse__style{
       border-width: 2vw;
       border-radius: 50vw;
       width: 3vw;
       height: 3vw;
       margin-right: 3vw;
   }

   .title__style{
      font-size: 6vw;
   color: var(--main-color);
  }

  .main__style__for__block{
    padding: 0;
    /* border: 2px red solid; */
  }

  .main__block__style{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5vw;
  }
}