.main__style__for__block{
    padding: 0 3vw;
    user-select: none;
    /* border: 2px red solid; */
}

.ellipse__style{
   border-style: solid;
    border-width: 0.4vw;
    border-color: #FFD8D1;
    border-radius: 3vw;
    width: 1vw;
    height: 1vw;
    margin-right: 1vw;
}

.title__style{
    font-size: 2vw;
 color: var(--main-color);
}

.block__title__style{
    display: flex;
    align-items: center;
}

.block__with__info{
    display: flex;
    flex-direction: row;
    align-items: center;
    border-color: var(--main-color);
    border-width: 0.3vw;
    border-style: solid;
    border-radius: 1vw;
    box-shadow: 0px 0px 0.5vw 0px #00000040;
    width: 90%;
    height: 9vw;
}

.block__info__style{
    /* border: 2px red solid; */
    display: flex;
    justify-content: space-around;
    margin-bottom: 3vw;
}

.img__style{
    width: 4vw;
    margin-left: 1vw;
    /* margin-top: 1vw; */
    /* border: 2px red solid; */
}

.h2__style{
    text-align: center;
    font-weight: bold;
    color: var(--main-color);
    font-size: 4vw;
    margin: 0;
    padding: 0;
}

.h5__style{
    text-align: center;
    color: var(--main-color);
    font-size: 1.6vw;
    margin: 0;
    padding: 0;
}

.h6__style{
    text-align: center;
    color: var(--main-color);
    font-size: 1.4vw;
    margin: 0;
    padding: 0;
}


.stat__style{
    display: flex;
    flex-direction: column;
    /* border: 2px red solid; */
    width: 100%;
    height: 100%;
    margin: 1vw;
    justify-content: center;
    align-items: center;

}

.stat__with__icon{
    display: flex;
    align-items: center;
    justify-content: center;
}

.anim__style__text{
    display: flex;
justify-content: right;
width: 100%;
}



@media (max-width: 770px) {
    .main__style__for__block{
        padding: 0;
        border-radius: 4vw;
        /* box-shadow: 0px 0px 3vw -1vw #00000040; */

        /* border: 2px red solid; */
    }
    .block__title__style{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ellipse__style{
         border-width: 2vw;
         border-radius: 50vw;
         width: 3vw;
         height: 3vw;
         margin-right: 3vw;
     }

     .title__style{
        font-size: 6vw;
     color: var(--main-color);
    }

    .block__with__info{
        display: flex;
        flex-direction: column;
        align-items: center;
        border-color: none;
        border-width: 0;
        border-style: none;
        border-radius: 1vw;
        box-shadow: 0px 0px 3vw -1vw #00000040;
        border: 0.9vw solid var(--main-color);
        width: 100%;
        height: auto;
        border-radius: 4vw;
    }

    .stat__style{
        display: flex;
        flex-direction: column;
        /* border: 2px red solid; */
        width: 100%;
        height: 100%;
        margin: 5vw;
        justify-content: center;
        align-items: center;
    
    }

    .h5__style{
        text-align: center;
        color: var(--main-color);
        font-size: 5.5vw;
        margin: 0;
        padding: 0;
    }

    .h2__style{
        text-align: center;
        font-weight: bold;
        color: var(--main-color);
        font-size: 10.5vw;
        margin: 0;
        padding: 0;
    }

    .img__style{
        width: 9vw;
        margin-left: 1vw;
        /* margin-top: 1vw; */
        /* border: 2px red solid; */
    }

    .h6__style{
        text-align: center;
        color: var(--main-color);
        font-size: 4vw;
        margin: 0;
        padding: 0;
    }
}
    

