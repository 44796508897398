.searche {
    border: 0.2vw var(--main-color) solid;
    border-radius: 1vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0vw 1vw;
    width: 50vw;

}

.searche {
    ::placeholder {
        color: rgb(207, 207, 207);
        font-size: 1.6vw;
    }
}

.searche img {
    width: 1.5vw;
    cursor: pointer;
}

.searche input {
    height: 70px;
    width: 100%;
    border: none;
    color: var(--main-color);
    font-size: 1.6vw;
    vertical-align: middle;

}

.searche input:focus {
    outline: none;
}

.wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 3vw;
    margin-bottom: 4vw;

}

.ellipse__style {
    border-style: solid;
    border-width: 0.4vw;
    border-color: #FFD8D1;
    border-radius: 3vw;
    width: 1vw;
    height: 1vw;
    margin-right: 1vw;
}


@media (max-width: 770px) {
    .searche input {
        height: 50px;
        width: 90%;
        font-size: 5vw;
    }

    .searche {
        border: 1vw var(--main-color) solid;
        border-radius: 4vw;
        padding: 1vw 5vw;
        width: 100%;
        display: flex;
        align-items: center;

    }


    .searche {
        ::placeholder {

            color: rgb(207, 207, 207);
            font-size: 5vw;
        }
    }


    .searche img {
        width: 10vw;
        cursor: pointer;
    }

    .ellipse__style {
        border-width: 2vw;
        border-radius: 50vw;
        width: 3vw;
        height: 3vw;
        margin-right: 3vw;
    }

    .block__title__style {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .title__style {
        font-size: 6vw;
        color: var(--main-color);
    }


    .wrapper {
        padding: 0 0vw;
        margin-bottom: 4vw;
    }
}