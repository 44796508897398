@charset "UTF-8";
@font-face {
  font-family: "Lilita";
  src: url("assets/fonts/lilita.ttf") format("ttf");
}
/* ===== Scrollbar CSS ===== */
/* Стиль для всего скроллбара */
::-webkit-scrollbar {
  width: 0.3vw; /* Ширина вертикального скроллбара */
}

/* Стиль для ползунка скроллбара */
::-webkit-scrollbar-thumb {
  background-color: var(--main-color); /* Цвет ползунка */
  border-radius: 4px; /* Скругление углов ползунка */
}

/* Стиль для фона скроллбара */
::-webkit-scrollbar-track {
  background-color: rgba(153, 59, 59, 0); /* Цвет фона скроллбара */
  border-radius: 4px; /* Скругление углов фона */
}

/* Убираем стрелки */
::-webkit-scrollbar-button {
  display: none; /* Скрываем кнопки прокрутки */
}

/* Добавляем эффект при наведении */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Цвет ползунка при наведении */
}

.content__container {
  width: 100%;
  height: auto;
  position: relative;
}

.wrapper {
  height: 100%;
}

.sidebar {
  position: fixed;
  z-index: 11;
}

.content {
  transition: 0.5s;
  padding: 0 2vw;
  box-sizing: border-box;
  min-height: 100%;
  padding-bottom: 12vw;
}

.title {
  width: 100%;
}
.title p {
  font-size: 2.5vw;
  text-align: center;
  font-family: "Lilita", "Roboto", Helvetica, sans-serif;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  -webkit-text-stroke: 2px var(--main-color);
}

.navbar-container {
  position: fixed;
  top: 0;
  z-index: 3;
  display: flex;
  align-items: start;
  transition: 0.5s;
  z-index: 10;
}

.footer-container {
  transition: 0.5s;
}

.footer {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: start;
  transition: 0.5s;
  width: 100%;
  height: 10vw;
  margin-top: -10vw;
}

@media (max-width: 770px) {
  .content__container {
    height: 100%;
    padding-top: 2vw;
  }
  .content {
    width: 100%;
    margin-left: 0;
    min-height: 50%;
    padding: 0 3vw 12vw 3vw;
  }
  .navbar-container {
    left: 0;
  }
  .title {
    font-weight: 500;
  }
  .title p {
    font-size: 7vw;
    font-weight: 500;
    -webkit-text-stroke: 1.5px var(--main-color);
  }
  .footer {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: start;
    transition: 0.5s;
    width: 100%;
    height: 10vw;
    margin-top: -10vw;
  }
}