.events__create__style li {
    display: flex;
    list-style: none;
    margin-bottom: 1vw;
}

.li__style {
    margin-bottom: 2vw;
}

.events__create__style span {
    font-size: 0.9vw;
    /* border: 2px violet solid; */
    font-family: "Roboto";
}

.events__create__style {
    /* border: 2px red solid; */
}

.text__style {
    width: 7vw;
    height: 2vw;
    display: flex;
    align-items: center;
}

.h3__style {
    font-size: 1.2vw;
    font-family: "Roboto";
    font-weight: 500;
    display: flex;
    justify-content: center;
    margin-bottom: 7vw;

}

.style__title title {
    /* border: 0px transparent solid; */
    /* color: red; */
}

.drop__down__style {
    width: 20vw;
    height: 13vw;
    box-shadow: 0px 0px 0.12vw 0px #000000;
    border-radius: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3vw;
}

.drop__down__style span {
    text-align: center;
    font-size: 1.1vw;
    font-family: "Roboto";
    color: rgb(138, 138, 138);
    font-weight: 500;
    line-height: 2vw;
}

.down__block__style {
    display: flex;
    width: 60vw;
}


.input__block {
    display: flex;
    gap: 1vw;
    justify-content: center;
}

/*Стиль radio btn */

.form_radio_btn {
   margin: 0vw;
}

.form_radio_btn input[type=radio] {
    display: none;
}

.form_radio_btn label {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 0.4vw;
    user-select: none;
    font-family: "Roboto";
    font-size: 0.9vw;
    box-shadow: 0px 0px 0.3vw 0px #00000040;
    background-color: white;
    width: 5vw;
    height: 2vw;
    font-weight: 500;
    border: 0.2vw transparent solid;
}

/* Checked */
.form_radio_btn input[type=radio]:checked+label {
    border: 0.2vw var(--hover-color) solid;

}

/* Disabled */
.form_radio_btn input[type=radio]:disabled+label {}


.presentation__container {
    display: flex;
    align-items: center;
    height: 2.3vw;
    padding: 0 1vw;
    box-shadow: 0px 0px 0.12vw 0px #000000;
    border-radius: 0.4vw;
    justify-content: space-between;
    gap: 10px;
    box-sizing: border-box;
    width: 60.1vw;
    /* max-width: 25%; */
}


@media (max-width: 770px) {
    .events__create__style li {
        display: flex;
        list-style: none;
        /* border: 2px orange solid; */
        margin: 0;
        width: 100%;
        margin-bottom: 3vw;
    }

    .li__style {
        margin-bottom: 5vw !important;
    }

    .events__create__style span {
        font-size: 3.5vw;
        /* border: 2px violet solid; */
        font-family: "Roboto";
        margin: 0;
    }

    .events__create__style {
        /* border: 2px red solid; */
        width: 100%;
    }

    .events__create__style ul {
        margin: 0;
        padding: 0;
    }

    .form_radio_btn label {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 3vw;
        user-select: none;
        font-family: "Roboto";
        font-size: 3.5vw;
        box-shadow: 0px 0px 3vw -1vw #00000040;
        background-color: white;
        width: 22vw;
        height: 7vw;
        font-weight: 500;
        border: 1vw transparent solid;
    }


    .form_radio_btn input[type=radio]:checked+label {
        border: 1vw var(--hover-color) solid;
    }

    .input__block {
        display: flex;
        gap: 0vw;
        justify-content: space-evenly;
        width: 100%;
        margin-bottom: 2vw;
    }

    .down__block__style {
        display: flex;
        width: 100%;
        /* border: 2px red solid; */
        justify-content: center;
    }

    .drop__down__style {
        width: 100%;
        height: 11vw;
        box-shadow: 0px 0px 2vw -0.7vw #00000040;
        border: solid 0.2vw #bbbbbb;
        border-radius: 2vw;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0 2vw;
        /* border: 2px green solid; */
        margin: 0;

    }

    .drop__down__style span {
        text-align: center;
        font-size: 3.5vw;
        font-family: "Roboto";
        color: rgb(122, 122, 122);
        font-weight: 500;
        line-height: 6vw;
    }

    .presentation__container {
        width: 100%;
        /* max-width: 25%; */
    }
}