.main__block__ticket__style ul {
    list-style: none;
    /* border: 2px blue solid; */
    margin: 0 1vw;
    padding: 0;
}

.main__block__ticket__style li {
    display: flex;
    margin-bottom: 1.5vw;
}

.main__block__ticket__style {
    /* border: 2px red solid; */
    background-color: white;
    width: 35vw;
    height: auto;
    box-shadow: 0px 0px 1.2vw 0px #00000040;
    border-radius: 2vw;
    padding-bottom: 1vw;
}

.main__block__ticket__style h3 {
    padding-top: 1vw;
    font-size: 1vw;
    font-family: "Roboto";
    font-weight: 500;
    display: flex;
    justify-content: center;
}

@media (max-width: 770px) {
    .main__block__ticket__style {
        /* border: 2px red solid; */
        background-color: white;
        width: 100%;
        height: auto;
        box-shadow: 0px 0px 3vw -1vw #00000040;
        border-radius: 4vw;
        padding-bottom: 3vw;

    }

    .main__block__ticket__style ul {

        list-style: none;
        /* border: 2px blue solid; */
        margin: 4vw 3vw;
        padding: 0;
    }

    .main__block__ticket__style h3 {
        padding-top: 3vw;
        font-size: 4vw;
        font-family: "Roboto";
        font-weight: 500;
        display: flex;
        justify-content: center;
    }
}