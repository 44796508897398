.main__block__style {
    /* border: 2px red solid; */
    /* margin-top: 2vw; */
    width: 100%;
    height: 13vw;
    padding: 1vw 4vw;
    padding-bottom: 1vw;
    background-color: var(--main-color);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.first__block__style {
    /* border: 2px violet solid; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2vw;
    height: 2vw;

}

.first__block__style span {
    /* border: 2px violet solid; */
    font-size: 0.9vw;
    font-family: "Roboto";
    height: auto;
    color: rgb(224, 224, 224);
    font-weight: 500;
}

.icon__style img {
    width: 1.2vw;
    height: 1.2vw;
    margin: 0 1vw;
}

.line__style {
    width: 100%;
    height: 0.1vw;
    background-color: #E0E0E0;
    margin: 0vw 0;
    /* border: 2px black solid; */
}

/* ----------------------------------------------- */


.second__block__style {
    /* padding: 0 2vw; */

    /* border: 2px yellow solid; */
    display: flex;
    width: 100%;
    align-items: start;
    justify-content: space-evenly;
    height: 7vw;
}

.second__block__style li {
    margin: 0.3vw 0;
}

.second__block__style li span a:hover {
    color: rgb(192, 192, 192);
}

.one__block__in__second span {
    font-size: 0.9vw;
    color: rgb(224, 224, 224);
    font-family: "Roboto";
    margin-bottom: 0vw;
    font-weight: 500;
}

.one__block__in__second {
    display: flex;
    /* border: 2px orange solid; */
    align-items: center;
    margin-bottom: 1vw;

}

.icon__gun__style {
    display: flex;
    fill: rgb(224, 224, 224);
    color: rgb(224, 224, 224);
    width: 1.1vw;
    height: 1.1vw;
    margin-right: 0.3vw;
    align-items: center;
    /* border: 2px black solid; */
}

.one__block {
    width: 40%;
    margin: 0 1vw;
}

.two__block {
    width: 30%;
}

.three__block {
    width: 30%;
}

.three__block ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.two__block ul {
    padding: 0;
    margin: 0;
    list-style: none;
}



.text__style {
    color: rgb(224, 224, 224);
    font-size: 0.8vw;
    font-family: "Roboto";
}

.text__style a {
    color: rgb(224, 224, 224);
    text-decoration: none;

}


/* ----------------------------------------------- */

.last__block__style a {
    font-size: 0.85vw;
    color: rgb(224, 224, 224);
    font-family: "Roboto";
    margin-left: 0.5vw;
    text-decoration: none;
}

.last__block__style {
    width: 100%;
    height: 1.4vw;
    display: flex;
    justify-content: center;
    font-size: 0.85vw;
    color: rgb(224, 224, 224);
    font-family: "Roboto";
    /* border: 2px solid green; */
    align-items: end;
}


@media (max-width: 770px) {

    .main__block__style {
        /* border: 2px red solid; */
        margin-top: 4vw;
        width: 100%;
        height: auto;
        padding: 3vw 6vw;
        padding-bottom: 2vw;
        background-color: var(--main-color);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .first__block__style {
        /* border: 2px violet solid; */
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        height: auto;
    }

    .icon__style img {
        width: 5vw;
        height: 5vw;
        margin: 0 6vw;
    }

    .line__style {
        width: 100%;
        height: 0.2vw;
        background-color: rgb(238, 238, 238);
        margin: 2vw 0;
        /* border: 2px black solid; */
    }

    /* ------------------------------------------------------- */

    .second__block__style {
        /* padding: 0 2vw; */
        /* border: 2px yellow solid; */
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: none;
        justify-content: none;
        height: auto;
    }

    .second__block__style li {
        margin: 1vw 0;
    }

    .second__block__style li span a:hover {
        color: rgb(192, 192, 192);
    }

    .one__block__in__second span {
        font-size: 4vw;
        color: rgb(238, 238, 238);
        font-family: "Roboto";
        margin-bottom: 0vw;
        font-weight: 500;
    }

    .one__block__in__second {
        display: flex;
        /* border: 2px orange solid; */
        align-items: center;
        margin-bottom: 1vw;
    }

    .icon__gun__style {
        display: flex;
        fill: rgb(238, 238, 238);
        color: rgb(238, 238, 238);
        width: 4vw;
        height: 4vw;
        margin-right: 2vw;
        align-items: center;
        /* border: 2px black solid; */
    }

    .one__block {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 1vw 1vw;
        margin-bottom: 4vw;
    }

    .two__block {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-bottom: 3vw;
    }

    .two__block li {
        text-align: center;
    }

    .three__block {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-bottom: 3vw;
    }

    .three__block li {
        text-align: center;
    }

    .three__block ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    .two__block ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    .text__style {
        color: rgb(238, 238, 238);
        font-size: 3.5vw;
        font-family: "Roboto";
        text-align: center;
    }

    .text__style a {
        color: rgb(238, 238, 238);
        text-decoration: none;
    }

    /* --------------------------------------------------- */

    .last__block__style a {
        font-size: 3.5vw;
        color: rgb(238, 238, 238);
        font-family: "Roboto";
        margin-left: 1vw;
        text-decoration: none;
    }

    .last__block__style {

        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        font-size: 3.5vw;
        color: rgb(238, 238, 238);
        font-family: "Roboto";
        /* border: 2px solid green; */
        align-items: end;
    }
}