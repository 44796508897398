.main-container {
  width: 100%;
  display: grid;
  position: relative;
}

.news {
  display: grid;
  height: 100%;
  gap: 0.9vw 0;
  position: relative;
}

.oauth-top {
  bottom: 10vh;
}

@media (max-width: 770px) {
  .news {
    width: 100%;
    padding-top: 3vw;
  }
  .scaner-style {
    display: flex;
    justify-content: end;
    margin-bottom: 3vw;
  }
}